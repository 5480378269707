import * as React from "react";
import Moralis from 'moralis-v1';

const CollectionsContext = React.createContext([]);

CollectionsContext.displayName = "CollectionsContext";

export const CollectionsProvider = ({ children }) => {
    const [allCollections, setAllCollections] = React.useState([])

    // obtener una coleccion usando el moralisId
    const get_collection = async (_contractAddress) => {
        try {
            const response = await Moralis.Cloud.run("GetCollections", {
                contractAddress: _contractAddress,
                onlyFirst: true,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error(`Error getting the collection with this address: ${_contractAddress}`);
        }
    };
    // obtener colecciones por filtros
    const get_collections = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetCollections", {
                // filtros
                category: filter._category,
                revealed: filter._revealed,
                // solo una
                slug: filter._slug,
                onlyFirst: filter._onlyFirst,
                moralisId: filter._moralisId,
                contractAddress: filter._contractAddress,
                // paginado
                offset: filter._offset,
                limit: filter._limit,
                // ordenar por
                sortBy: filter._sortBy,
                asc: filter._asc,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the Collections: ' + error.message)
        }
    }
    // obtener todas las colecciones en la db (con paginado y orden)
    const get_all_collections = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetCollections", {
                // paginado
                offset: filter._offset,
                limit: filter._limit,
                // ordenar por
                sortBy: filter._sortBy,
                asc: filter._asc,
            });
            setAllCollections(response)
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the Collections: ' + error.message)
        }
    };
    // solo el total de colecciones
    const get_collections_length = async (_category) => {
        try {
            const response = await Moralis.Cloud.run("GetCollections", {
                category: _category,
                onlyLength: true,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the Collections: ' + error.message)
        }
    };
    // crear coleccion
    const create_collection = async (params) => {
        try {
            const response = await Moralis.Cloud.run("CreateCollection", {
                displayName: params._displayName,
                slug: params._slug,
                category: params._category,
                categoryId: params._categoryId,
                revealed: params._revealed,
                revealMetadata: params._revealMetadata,
                contractAddress: params._contractAddress,
                openseaMetadata: params._openseaMetadata,
                price: params._price,
                creator: params._creator,
                cmsId: params._cmsId,
                type: params._type,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context creating a new category: ' + error.message)
        }
    };
    // editar una coleccion
    const edit_collection = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("EditCategory", {
                moralisId: filter._moralisId,
                newName: filter._newName,
                newImage: filter._newImage,
                newDescription: filter._newDescription,
                newBanner: filter._newBanner,
                newOrder: filter._newOrder,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error(`Error editing the category with id: ${filter._moralisId}`);
        }
    };

    return (
        <CollectionsContext.Provider value={{
            create_collection,
            get_collections,
            get_collections_length,
            get_all_collections,
            get_collection,
            allCollections
        }}>
            {children}
        </CollectionsContext.Provider>
    );
};

export const useCollections = () => {
    const context = React.useContext(CollectionsContext);
    return context;
};