import React from "react";
import Moralis from 'moralis-v1';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Typography, Chip, Button } from '@mui/material'
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import ReactPlayer from "react-player";

const CardStyled = styled(Card)`
  border-radius: 15px;
  --card-min-width: 250px;
  --card-max-width: 260px;
  --card-min-height: 370px;
  --card-max-height: 380px;
  min-width: var(--card-min-width);
  max-width: var(--card-max-width);
  min-height: var(--card-min-height);
  max-height: var(--card-max-height);
  background: #FFFFFF;
  color: var(--primary-main);
  margin-bottom: 30px;
`;

const CustomTag = styled(Chip)`
  position: relative;
  padding: 0;
  left: 75%;
`;

const AuthorTitle = styled("label")`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: -5px;
  min-height: calc(var(--card-min-height) - 270px);
  max-height: calc(var(--card-max-height) - 270px);
`;

const DTCsCard = ({ _name, _img, _link = '/', _state = 1, _itemId }) => {

    const [video, setVideo] = React.useState(false)
    const [stateName, setStateName] = React.useState('')
    const [colorState, setColorState] = React.useState('')

    const isVideo = async (imageOrVideo) => {
        var req = await fetch(imageOrVideo, { method: 'HEAD' });
        if (req.headers.get('content-type') === 'video/mp4') {
            setVideo(true)
        } else {
            setVideo(false)
        }
    }

    React.useEffect(() => {
        isVideo(_img);
    }, [_img])

    const getStateName = (state) => {
        switch (state) {
            case 1:
                setStateName('Draft')
                setColorState('#9C26B1')
                break;
            case 2:
                setStateName('On Sale')
                setColorState('#D22F2E')
                break;
            case 3:
                setStateName('Minted')
                setColorState('#2E7D32')
                break;
            default:
                setStateName('No info')
                setColorState('noinfo')
                break;
        }
    };

    const put_as_sold = async (id) => {
        try {
            const response = await Moralis.Cloud.run("sellDTC", { moralisId: id })
            if (response.success) window.location.reload();
            if (!response.success) throw response.error;
        } catch (error) {
            console.error(error)
        }
    };

    const pause_DTC = async (id) => {
        try {
            const response = await Moralis.Cloud.run("pauseDTC", { moralisId: id });
            if (response.success) window.location.reload();
            if (!response.success) throw response.error;
        } catch (error) {
            console.error(error)
        }
    };


    React.useEffect(() => {
        getStateName(_state)
    }, [_state])

    return (
        <CardStyled className="hover-up" style={{ margin: '1rem' }}>
            <div style={{ position: 'relative' }}>

                <Link to={_link}>
                    {video

                        ? <ReactPlayer height='250px' width='100%' url={_img} loop playing muted />

                        : <CardMedia
                            component="img"
                            height='270'
                            image={_img}
                            alt={_name}
                            sx={{ backgroundColor: '#FFFFFF' }}
                        />
                    }
                </Link>
                <CardContent style={{ padding: '0px', paddingBottom: '5px', paddingTop: '5px' }}>

                    <Button variant="contained" size="small" className="d-flex" style={{ position: "absolute", color: "white", top: 10, right: "25%", backgroundColor: colorState }}>{stateName}</Button>

                    <AuthorTitle>
                        <Link to={_link}>
                            <Row>
                                <Typography gutterBottom color="primary" variant="button" component="div" sx={{ marginTop: '1rem', paddingTop: '0.5rem' }} fontSize={'inherit'}>
                                    {_name}
                                </Typography>
                            </Row>
                        </Link>
                        <Row className=" d-flex justify-content-center">
                            {/* Si esta en DRAFT */}
                            {_state === 1 &&
                                <Button size="small" variant='contained' className="btn btn-pausar" style={{ width: '25%', marginBottom: '1rem' }} onClick={() => put_as_sold(_itemId)}>Sell</Button>
                            }
                            {/* Si esta en VENTA o MINTEADO */}
                            {(_state === 2 || _state === 3) &&
                                <Button size="small" variant='contained' className="btn" style={{ width: '25%', marginBottom: '1rem' }} onClick={() => pause_DTC(_itemId)}>Pause</Button>
                            }

                        </Row>
                    </AuthorTitle>
                </CardContent>
            </div>
        </CardStyled >
    );
};

export default DTCsCard