import * as React from 'react';
import { ethers } from 'ethers';
import Moralis from 'moralis-v1';
import { rolesAddress, rolesAbi } from '../../utils/rolesContract';
import { useNetwork } from '../../context/NetworkContext';
// componentes
import useUploading from "../../hooks/useUploading";
import WaitingModal from '../../components/Modals/WaitingModal/WaitingModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
// tabla
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'var(--primary-main)',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--primary-main)',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const RevokeRole = () => {
    const { getMaxGas } = useNetwork();
    // hook de espera
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);

    const [allRoles, setAllRoles] = React.useState([]);
    const get_roles = async () => {
        try {
            const response = await Moralis.Cloud.run("GetRolesTable", {});
            setAllRoles(response)
        } catch (error) {
            console.error(error)
        }
    };

    React.useEffect(() => {
        get_roles();
    }, [])

    const revoke_role = async (_role, _account) => {
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        try {
            const { ethereum } = window;
            if (!ethereum) throw new Error("No Metamask!");

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            let contract = new ethers.Contract(rolesAddress, rolesAbi, signer);
            let fastestGas = await getMaxGas();
            const tx = await contract.revokeRole(_role, _account, { gasPrice: fastestGas });
            await tx.wait();
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
        } catch (error) {
            console.error(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };

    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Revoking role"} _subtitle={`A role is being revoked. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Role revoked"} _subtitle={`The role was succesfully revoked.`} _redirect={`/panel/admin/roles`} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Wallet address</StyledTableCell>
                            <StyledTableCell align="center">Role</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRoles?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    {row.attributes.ethAddress}
                                </StyledTableCell>
                                <StyledTableCell align="center">{(row.attributes.roleName).toUpperCase()}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button variant="outlined" onClick={() => revoke_role(row.attributes.roleHash, row.attributes.ethAddress)}> Revoke Role </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default RevokeRole