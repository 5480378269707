import * as React from "react";
import { buildClient } from '@datocms/cma-client-browser';

const CMSContext = React.createContext([]);

CMSContext.displayName = "CMSContext";

export const CMSProvider = ({ children }) => {

    const CMS_IDS = {
        CATEGORY: '241678',
        COLLECTION: '323435',
    };
    const cmsClient = buildClient({
        apiToken: process.env.REACT_APP_CMS_API_KEY,
        environment: process.env.REACT_APP_CMS_ENVIRONMENT,
    });

    return (
        <CMSContext.Provider value={{
            CMS_IDS,
            cmsClient,
        }}>
            {children}
        </CMSContext.Provider>
    );
};

export const useCMS = () => {
    const context = React.useContext(CMSContext);
    return context;
};