import { Typography } from '@mui/material';
import * as React from 'react';
import { Container } from 'react-bootstrap';
import CardsDisplay from '../../../models/CardsDisplay/CardsDisplay';
import { useQuery } from "graphql-hooks";
import Loading from "../../../components/Loading/Loading";

const AllCategories = () => {
  // paginacion
  const [page, setPage] = React.useState(1);
  const handlePaginationChange = (e, value) => {
    setPage(value)
  };
  const limit = 5;

  const categories = useQuery(`query MyQuery {
    allCategories (first: ${limit}, skip: ${(Number(page - 1) * limit)}) {
          name
          slug
          destination
          lightIcon {
            url
          }
          darkIcon {
            url
          }
          coverImage {
            url
          }
          bannerImage {
            url
          }
      }
  }`);

  const allCategories = useQuery(`query MyQuery {
    allCategories (first: 50, skip: 0) {
      slug
    }
  }`);

  if (categories.loading || allCategories.loading) return <Loading />
  return (
    <Container>
      <Typography component="h2" variant='h4' color="primary" className="pages-title my-4">DTC Category</Typography>
      <CardsDisplay _cards={categories.data.allCategories} _length={allCategories.data.allCategories.length} _type={'categories'}
        _page={page} _limit={limit} _handlePaginationChange={handlePaginationChange} />
    </Container>
  )
}

export default AllCategories