import * as React from 'react'
import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const HelperIcon = ({ _help, _placement, _arrow = true }) => {
    if (_help) {
        return <Tooltip sx={{ width: 'auto !important' }} title={_help} arrow={_arrow} placement={_placement ? _placement : 'left'}>
            <IconButton>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    } else
        return <IconButton sx={{ width: 'auto !important' }}>
            <InfoIcon />
        </IconButton>

}

export default HelperIcon