import * as React from "react";
import './Cards.css';
import { IconButton } from '@mui/material'
import { useParams, Link } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const AddCollectionCard = ({ _category = false }) => {
    let { category } = useParams();
    return (
        <Link to={_category ? `/panel/create-category` : `/panel/${category}/create-collection`}>
            <div className="d-flex align-items-center justify-content-center hover-up add-collection">
                <IconButton className="d-flex flex-column">
                    <AddCircleOutlineIcon sx={{ marginBottom: '1rem' }} />
                    {_category 
                    ? <p>Create Category</p>
                    : <p>Create Collection</p>}
                </IconButton>
            </div>
        </Link>
    );
};

export default AddCollectionCard