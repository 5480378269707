import * as React from 'react';
import './WrongNetwork.css';
import { Button } from '@mui/material';
import { useNetwork } from '../../context/NetworkContext';

const WrongNetwork = () => {
    // funciones de la red
    const { wrongNetwork, switchNetwork } = useNetwork();

    if (wrongNetwork) {
        return <div className="wrong-network">
            This web only works in Polygon Mainnet, you can change it by
            &nbsp;&nbsp;&nbsp;
            <Button sx={{ color: 'white' }} onClick={() => switchNetwork()}>
                clicking here
            </Button>
        </div>
    } else {
        return <></>
    }
}

export default WrongNetwork