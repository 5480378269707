import { createTheme } from '@mui/material';

const customTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#2A407E',
            dark: '#1D2C58',
            light: '#546697',
        },
        secondary: {
            main: '#2A407E',
            dark: '#2A407E',
        },
        error: {
            main: '#dc3545',
        },
        info: {
            main: '#ffffff'
        }
    },
    typography: {
        fontFamily: 'Inter',
        fontWeightRegular: 500,
        button: {
            fontWeight: 600,
        },
    },
    spacing: 10,
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiTooltip: {
            arrow: true,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            md: 870,
            lg: 1200,
            xl: 1450,
        },
    },
    components: {
        MUIDataTableToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#AE74FF',
                    color: 'white',
                    borderTopLeftRadius: '22px',
                    borderTopRightRadius: '22px'
                }
            }
        },
        MUIDataTable: {
            styleOverrides: {
                root: {
                    borderRadius: '22px',

                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderRadius: '22px',

                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: 'primary',

                }
            }
        }
    }
});

export default customTheme;