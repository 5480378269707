import * as React from 'react';
import { Outlet } from 'react-router-dom';
import WrongNetwork from './WrongNetwork/WrongNetwork'
import DrawerLayout from './Drawer/DrawerLayout';

const Layout = () => {
    return (
        <>
            <WrongNetwork />
            <DrawerLayout>
                <Outlet />
            </DrawerLayout>
        </>
    )
}

export default Layout