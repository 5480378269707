import * as React from 'react';
import ReactPlayer from "react-player";

const VideoPlayer = ({ _img, _controls }) => {
    const [video, setVideo] = React.useState(false)

    const isVideo = async (imageOrVideo) => {
        var req = await fetch(imageOrVideo, { method: 'HEAD' });
        if (req.headers.get('content-type') === 'video/mp4') {
            setVideo(true)
        } else {
            setVideo(false)
        }
    }

    React.useEffect(() => {
        isVideo(_img);
    }, [_img])

    if (video) {
        return <ReactPlayer height='420px' width='100%' url={_img} loop playing muted={_controls} controls={!_controls} />
    } else {
        return <img src={_img} alt={`DTC`} height="420px" style={{ borderRadius: '5%' }} />
    }
}

export default VideoPlayer