/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ethers } from "ethers";
import Hash from 'ipfs-only-hash';
import ReactPlayer from 'react-player'
import { dtcReducer, DTC_INITIAL_STATE, ACTION_TYPE } from './campaignReducer';
import { useIPFS } from '../../context/IPFSContext';
import { lazyMintAddress } from '../../utils/lazyMintContract';
// modales
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
// Formulario
import { Row, Col } from "react-bootstrap";
import { Button, Typography, Select, MenuItem, Tab, IconButton, Divider, Input } from '@mui/material'
// tabs
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ImageIcon from '@mui/icons-material/Image';
import useUploading from "../../hooks/useUploading";
import { useDTCs } from '../../context/DTCsContext';
import { useQuery } from 'graphql-hooks';
// Iconos para properties
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import csvDownload from 'json-to-csv-export'
import Papa from 'papaparse'
import axios from 'axios';
import { useCampaign } from '../../context/CampaignContext';



const CreateCampaign = ({ _categories }) => {
    const { create_template } = useDTCs();
    const { upload_csv } = useDTCs();
    const { upload_image, upload_json } = useIPFS();
    const { getContactList, get } = useCampaign();

    // custom hooks y reducers juntos
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);
    // formulario reducer
    const [dtcState, dispatch] = React.useReducer(dtcReducer, DTC_INITIAL_STATE);
    const handleInputChange = (evt, child) => {
        dispatch({
            type: ACTION_TYPE.CHANGE_INPUT,
            payload: { name: evt.target.name, value: evt.target.value, address: child ? child.props.dataaddress : false, cmsId: child ? child.props.dataid : false },
        })
    };
    const handleImgChange = (evt) => {
        if (evt.target.files && evt.target.files.length > 0) {
            dispatch({
                type: ACTION_TYPE.SET_IMAGE,
                payload: { name: evt.target.name, value: evt.target.files[0], isVideo: evt.target.files[0].type === 'video/mp4' },
            })
        }
    };

    const addAttribute = (_type) => {
        switch (_type) {
            case 'rankings':
                const newRanking = { "trait_type": "", "value": Number("0"), "id": Date.now() };
                dispatch({
                    type: ACTION_TYPE.ADD_ATTRIBUTE,
                    payload: { name: 'rankings', value: newRanking },
                })
                break;
            case 'stats':
                const newStat = { "display_type": "number", "trait_type": "", "value": Number("0"), "id": Date.now() }
                dispatch({
                    type: ACTION_TYPE.ADD_ATTRIBUTE,
                    payload: { name: 'stats', value: newStat },
                })
                break;
            case 'properties':
                const newProperty = { "trait_type": "", "value": "", "id": Date.now() }
                dispatch({
                    type: ACTION_TYPE.ADD_ATTRIBUTE,
                    payload: { name: 'properties', value: newProperty },
                })
                break;
            default:
                break;
        }
    };
   
    const removeAttribute = (_type, _id) => {
        switch (_type) {
            case 'rankings':
                dispatch({
                    type: ACTION_TYPE.REMOVE_ATTRIBUTE,
                    payload: { name: _type, value: _id },
                })
                break;
            case 'stats':
                dispatch({
                    type: ACTION_TYPE.REMOVE_ATTRIBUTE,
                    payload: { name: _type, value: _id },
                })
                break;
            case 'properties':
                dispatch({
                    type: ACTION_TYPE.REMOVE_ATTRIBUTE,
                    payload: { name: _type, value: _id },
                })
                break;
            default:
                break;
        }
        dispatch({
            type: ACTION_TYPE.SET_ATTRIBUTES,
        })
    };
    const handleAttrChange = (evt, _index, _type) => {
        switch (_type) {
            case 'rankings':
                dispatch({
                    type: ACTION_TYPE.CHANGE_ATTRIBUTE,
                    payload: { type: _type, index: _index, value: evt.target.value, name: evt.target.name },
                })
                break;
            case 'stats':
                dispatch({
                    type: ACTION_TYPE.CHANGE_ATTRIBUTE,
                    payload: { type: _type, index: _index, value: evt.target.value, name: evt.target.name },
                })
                break;
            case 'properties':
                dispatch({
                    type: ACTION_TYPE.CHANGE_ATTRIBUTE,
                    payload: { type: _type, index: _index, value: evt.target.value, name: evt.target.name },
                })
                break;
            default:
                break;
        }
    };
    // Variables para el tab de atributos y propiedades
    const [tabValue, setValue] = React.useState("1");
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // cada vez que se cambia alguna dependencia dentro de atributos se actualiza este parametro
    React.useEffect(() => {
        dispatch({
            type: ACTION_TYPE.SET_ATTRIBUTES,
        })
    }, [dtcState.properties, dtcState.stats, dtcState.rankings])

    const [categoryId, setCategoryId] = React.useState('')
    React.useEffect(() => {
        setCategoryId(dtcState.categoryId);
    }, [dtcState.category])

    const [savedTemplate, setSavedTemplate] = React.useState(false);
    React.useEffect(() => {
        setSavedTemplate(waitState.success);
    }, [waitState.success])

    const [templateId, setTemplateId] = React.useState('');

    const collectionsIn = useQuery(`query MyQuery {
        allCollections(filter: {category: {eq: ${categoryId}}}) {
          id
          name
          slug
          description
          coverImage {
            url
          }
          contractAddress
          bannerImage {
            url
          }
          destination
        }
    }`);

    // funciones
    const handleSubmit = async (e, _state) => {
        e.preventDefault();
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        console.log('dtcState: ', dtcState);
        uploadToIPFS(_state);
    };
    // subir todo a IPFS
    const uploadToIPFS = async (_state) => {
        try {
            // imagenes
            const imgUrl = await upload_image(dtcState.image.source);

            // subo el JSON con la metadata
            const getCID = async (json) => {
                // Convierto el JSON a Uint8Array
                let str = JSON.stringify(json, null, 0);
                let ret = new Uint8Array(str.length);
                for (let i = 0; i < str.length; i++) {
                    ret[i] = str.charCodeAt(i);
                }
                // Con eso => obtengo el CID de IPFS
                const hash = await Hash.of(ret);
                // Pasa a la firma
                // startSignature(hash, imgUrl, json);
            };

            // Defino el Json con la Metadata
            if (dtcState.attributes.length > 0) {
                let json = {
                    name: dtcState.name,
                    description: dtcState.description,
                    image: `https://digitaltradingcards.mypinata.cloud/ipfs/${imgUrl}`,
                    attributes: dtcState.attributes
                };
                console.log(json)
                const jsonUrl = await upload_json(json);
                console.log(jsonUrl)
                uploadToMoralis(jsonUrl)
                //startSignature(jsonUrl, imgUrl, json, _state);
                // getCID(json) TODO: esto se activa cuando (si) aplicamos Lazy Minting
            } else {
                let json = {
                    name: dtcState.name,
                    description: dtcState.description,
                    image: `https://digitaltradingcards.mypinata.cloud/ipfs/${imgUrl}`,
                };
                console.log(json)
                const jsonUrl = await upload_json(json);
                console.log(jsonUrl)
                uploadToMoralis(jsonUrl)
                // startSignature(jsonUrl, imgUrl, json, _state);
                // getCID(json) TODO: esto se activa cuando (si) aplicamos Lazy Minting
            }

        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };
    
    // subida a la db en moralis
    const uploadToMoralis = async (_json) => {
        try {
            console.log(dtcState)
            const response = await create_template({
                _name: dtcState.name,
                _templateUri: `https://digitaltradingcards.mypinata.cloud/ipfs/${_json}`,
                _collectionAddress: dtcState.collectionAddress,
                _maxSupply: dtcState.totalSupply,
            });
            if (response.success) {
                console.log(response)
                setTemplateId(response.id);
                waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS })
            }
            
            if (!response.success) throw new Error("Error")
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };

    const [contactList, setContactList] = React.useState([]);
    const [listSize, setListSize] = React.useState(0);
    const [listId, setListId] = React.useState(0);
    const [listName, setListName] = React.useState("");

    const handleInputListChange = (evt, child) => {
        setListId(child.props.dataid);
        setListName(child.props.value);
        setListSize(contactList[child.props.dataid - 1].metaData.size)
        console.log(contactList[child.props.dataid - 1].metaData.size)
    }

    React.useEffect(() => {
        getContactList().then((res) => {
            setContactList(res.lists);
            console.log(res)
        });
    }, [])

    const generateURL = () => {
        return `https://dtcqa.com/claim-nft?listId=${listId}&templateId=${templateId}&recordId={{contact.hs_object_id}}`
    }

    return (
        <>
        <form onSubmit={(e) => handleSubmit(e, 1)}>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Creating DTC"} _subtitle={`The DTC ${dtcState.name} is being created. Please wait.`} />
            {/* {<ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"DTC created"} _subtitle={`The DTC ${dtcState.name} has been created and successfully uploaded!`} _redirect={`/panel/${dtcState.collectionAddress}`} />} */}
            {/* Imagen */}
            <Row className="mt-3 mb-5">
                <Col className="px-0">
                    <label style={{ 'border': dtcState.image ? 'none' : '', 'justifyContent': dtcState.image ? 'flex-start' : 'center' }}
                        htmlFor="image" className="custom-upload" >
                        {dtcState.image
                            ? dtcState.image.isVideo
                                ? <ReactPlayer height='100%' width='100%' url={URL.createObjectURL(dtcState.image.source)} loop playing muted />
                                : <img src={URL.createObjectURL(dtcState.image.source)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem", maxWidth: '340px', borderRadius: '5%' }} />
                            : <><ImageIcon color="disabled" sx={{ fontSize: '4rem' }} /><Typography variant="h6" sx={{ color: '#999999' }}>DTC Image</Typography></>}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif,.mp4" name="image" id="image" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            {/* nombre */}
            <Row className="my-5">
                <BootstrapInput name="name" className="p-0" placeholder="DTC name:" type="text" onChange={handleInputChange} />
            </Row>
            {/* descripcion */}
            <Row className="my-5">
                <BootstrapInput name="description" placeholder="DTC description:" type="text" multiline rows="4" onChange={handleInputChange} />
            </Row>
            {/* max supply */}
            <Row className="my-5">
                <BootstrapInput name="totalSupply" className="p-0" placeholder="Max supply:" type="number" onChange={handleInputChange} />
            </Row>

            {/* Tabs de atributos */}
            <TabContext value={tabValue}>
                {/* Header */}
                <TabList onChange={handleTabChange} aria-label="properties tab" >
                    <Tab label="Properties" value="1" />
                    <Tab label="Stats" value="2" />
                    <Tab label="Rankings" value="3" />
                </TabList>

                {/*  Tab de propiedades  */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="1" className="px-0">
                    {dtcState.properties?.length > 0 && dtcState.properties?.map((element, index) => (
                        <div className="form-inline mb-3" key={element.id}>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                                <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handleAttrChange(e, index, 'properties')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Value:</Typography></label>
                                <BootstrapInput type="text" name="value" defaultValue={element.value} onChange={e => handleAttrChange(e, index, 'properties')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <IconButton type="button" onClick={() => removeAttribute('properties', element.id)}><RemoveCircleOutlineIcon /></IconButton>
                        </div>
                    ))}
                    <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addAttribute('properties')}>
                        <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                        Add Property
                    </Button>
                </TabPanel>

                {/* Tab de Stats */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="2">
                    {dtcState.stats?.length > 0 && dtcState.stats?.map((element, index) => (
                        <div className="form-inline mb-3" key={element.id}>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                                <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handleAttrChange(e, index, 'stats')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Value:</Typography></label>
                                <BootstrapInput type="number" name="value" defaultValue={element.value} onChange={e => handleAttrChange(e, index, 'stats')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <IconButton type="button" onClick={() => removeAttribute('stats', element.id)}><RemoveCircleOutlineIcon /></IconButton>
                        </div>
                    ))}
                    <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addAttribute('stats')}>
                        <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                        Add Stat
                    </Button>
                </TabPanel>

                {/* Tab de Rankings */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="3">
                    {dtcState.rankings?.length > 0 && dtcState.rankings?.map((element, index) => (
                        <div className="form-inline mb-3" key={element.id}>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                                <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handleAttrChange(e, index, 'rankings')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <div className="d-inline mx-2">
                                <label className="mx-2"><Typography variant="button" component="span">Value:</Typography></label>
                                <BootstrapInput type="text" name="value" defaultValue={element.value} onChange={e => handleAttrChange(e, index, 'rankings')} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                            </div>
                            <IconButton type="button" onClick={() => removeAttribute('rankings', element.id)}><RemoveCircleOutlineIcon /></IconButton>
                        </div>
                    ))}
                    <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addAttribute('rankings')}>
                        <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                        Add Ranking
                    </Button>
                </TabPanel>
            </TabContext>

            {/* select de categoria */}
            <Row className="my-4">
                <Select value={dtcState.category} name="category" displayEmpty onChange={handleInputChange} input={<BootstrapInput className="p-0" />}>
                    <MenuItem disabled dataid="" value="">
                        Select a category
                    </MenuItem>
                    {_categories?.length > 0 && _categories.map((category, index) => <MenuItem key={index} dataid={category.id} value={category.slug}>{category.name}</MenuItem>)}
                </Select>
            </Row>

            {/* select de coleccion */}
            <Row className="my-4">
                <Select value={dtcState.collection} name="collection" displayEmpty onChange={handleInputChange} input={<BootstrapInput className="p-0" />}>
                    <MenuItem disabled dataaddress="" value="">
                        Select a collection
                    </MenuItem>
                    {(collectionsIn.loading || collectionsIn.data?.allCollections.length < 1)

                        ? null

                        : collectionsIn.data?.allCollections.map((collection, index) => <MenuItem key={index} dataaddress={collection.contractAddress} value={collection.slug}>{collection.name}</MenuItem>)}
                
                </Select>
            </Row>
            
            {/* select lista de contactos */}
            {/* <Row className="my-4">
                <Select value={listName || ''} name="listName" displayEmpty onChange={handleInputListChange} input={<BootstrapInput className="p-0" />}>
                    <MenuItem disabled dataid="" value="">
                        Select a contact list
                    </MenuItem>
                    {contactList?.length > 0 && contactList.map((list, index) => <MenuItem key={index} dataid={list.listId} value={list.name}>{list.name}</MenuItem>)}
                </Select>
            </Row> */}

            {/* boton de confirmacion */}
            <Row className="my-5">
                <Col md={4} className="ps-0">
                    <Button type="button" color="secondary" variant="contained"
                        disabled={!(dtcState.name !== '' && dtcState.collection && dtcState.category && dtcState.image && !savedTemplate)}
                        onClick={(e) => handleSubmit(e, 1)} sx={{ fontSize: '1.15rem' }}>
                        Save Template
                    </Button>
                </Col>
            </Row>

        </form >

        {savedTemplate 
        ? 
        <>
        <Divider className="my-1" sx={{ width: '100%', height: '1px', borderColor: 'var(--primary-main)', opacity: '1', marginBottom:'10rem' }} />
        <Row className="my-4 mt-5">
                <Select value={listName || ''} name="listName" displayEmpty onChange={handleInputListChange} input={<BootstrapInput className="p-0" />}>
                        <MenuItem disabled dataid="" value="">
                            Select a contact list
                        </MenuItem>
                        {contactList?.length > 0 && contactList.map((list, index) => <MenuItem key={index} dataid={list.listId} value={list.name}>{list.name}</MenuItem>)}
                </Select>
            {/* <Col className="px-0">
                    <label style={{ 'border': dtcState.image ? 'none' : '', 'justifyContent': dtcState.image ? 'flex-start' : 'center' }}
                        className="custom-upload">
                            {dtcState?.image?.isVideo
                                ? <ReactPlayer height='100%' width='100%' url={URL.createObjectURL(dtcState.image.source)} loop playing muted />
                                : <img src={URL.createObjectURL(dtcState.image.source)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem", maxWidth: '340px', borderRadius: '5%' }} />
                            }
                    </label>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
                <Button type="button" component="label" variant="contained" size="large" sx={{ fontSize: '1rem', marginTop: "1rem" }}>
                    Import CSV
                    <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    />
                </Button>
            </Col> */}
        </Row>
        { listId ? <Row className="my-4 mt-3 justify-content-center" sx={{marginTop: '20rem'}}>{generateURL()}</Row> : null}
        </>
        : null}
    </>
    )
}

export default CreateCampaign