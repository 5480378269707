import React from 'react'
import { ethers } from 'ethers';
import { Container, Row, Col } from "react-bootstrap";
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import WaitingModal from "../../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import ContractSetter from '../../../components/ContractSetter/ContractSetter';
import { marketplaceAddress, marketplaceAbi } from '../../../utils/marketplaceContract'
import { useNetwork } from '../../../context/NetworkContext'

const CollectorConfig = () => {

    const [feeAccount, setFeeAccount] = React.useState("")
    const [oldFeeAccount, setOldFeeAccount] = React.useState("")
    const [initialLoading, setInitialLoading] = React.useState(false)
    const [waiting, setWaiting] = React.useState(false)
    const [confirmed, setConfirmed] = React.useState(false)
    const [error, setError] = React.useState(false)

    const { onlyReadProvider, getMaxGas } = useNetwork();

     // getters que se leen desde el contrato
     const get_fee_account = async () => {
        try {
            let contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, onlyReadProvider);
            const response = await contract.feeAccount();
            setFeeAccount(response)
            
            return response
        } catch (error) {
            throw new Error("Error getting the fee account", error);
        }
    };
    
    // funcion que resuelve todas estas promesas
    const get_all_data = () => {
        Promise.all([
            get_fee_account(),
            
        ])
            .then((response) => {
                setOldFeeAccount(response);
            })
            .then(() => setInitialLoading(false))
            .catch((error) => console.error(error))
    };
    React.useEffect(() => {
        get_all_data();
    }, [])

    // setters
    const change_fee_account = async (_account) => {
        setWaiting(true)
        try {
            const { ethereum } = window;
            if (!ethereum) throw new Error('No Metamask installed');
            // Conecto la wallet
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            let contract = new ethers.Contract(
                marketplaceAddress,
                marketplaceAbi,
                signer
            );
            let fastestGas = await getMaxGas();
            const tx = await contract.setfeeAccount(_account, { gasPrice: fastestGas })
            await tx.wait()
                /* .then((response ) => console.log(response)) */
                .then(() => setConfirmed(true))
                .catch((error) => {console.error(error); setError(true)})
        } catch (e) {
            setWaiting(false)
            setError(true)
            throw new Error("Error changing collector wallet");
        }
    };

  return (
    <Container>
            <WaitingModal _open={waiting} _handleClose={() => null} _title={"Updating collector's address "} _subtitle={`The address is beeing updated. Please wait.`} />
            <ConfirmationModal _open={confirmed} _handleClose={() => setConfirmed(false)} _title={"Address updated"} _subtitle={`The address was updated`} _redirect={window.location.pathname} />
            <ConfirmationModal _open={error} _handleClose={() => setError(false)} _title={"There was an error updating the address"} _subtitle={`Please check the console for more information`} _redirect={window.location.pathname} />
            <GoBack />
            <Row className="d-flex align-items-center">
                <Col>
                    <Typography variant="h4" component="h2" sx={{ fontWeight: '700', marginBottom: '2rem' }} color="primary" className="mt-1">
                        Collector's Wallet administrator
                    </Typography>
                </Col>
                {/* <ContractAddress _addr={"0x25454"} /> */}
            </Row>

                <ContractSetter _key="Collector's wallet" _helper={'Wallet that receives the fees from the marketplace.'} _value={feeAccount} _onClick={change_fee_account} _type="text" />
            

        </Container>
  )
}

export default CollectorConfig