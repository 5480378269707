import * as React from 'react';
import { Container } from 'react-bootstrap';
import './Loading.css';

const Loading = () => {
    return (
        <Container fluid className="d-flex justify-content-center align-items-center h-50">
            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </Container>
    )
}

export default Loading