import * as React from "react";
import Moralis from 'moralis-v1';
import { useCMS } from './CMSContext';

const CategoriesContext = React.createContext([]);

CategoriesContext.displayName = "CategoriesContext";

export const CategoriesProvider = ({ children }) => {

    const { cmsClient, CMS_IDS } = useCMS();

    const get_category = async (_urlName) => {
        try {
            const response = await Moralis.Cloud.run("GetCategories", {
                urlName: _urlName,
                onlyFirst: true,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error(`Error getting the category with id: ${_urlName}`);
        }
    };

    const get_all_categories_cms = async (filter) => {
        try {
            const records = await cmsClient.items.list({
                filter: {
                    type: CMS_IDS.CATEGORY,
                },
                page: {
                    offset: filter._offset,
                    limit: filter._limit,
                },
                // order_by: `${filter._sortBy}_${filter._asc ? 'ASC' : 'DESC'}`
            });
            return records
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the categories: ' + error.message)
        }
    };

    const get_all_categories_length_cms = async () => {
        try {
            const records = await cmsClient.items.list({
                filter: {
                    type: CMS_IDS.CATEGORY,
                },
                page: {
                    offset: 0,
                    limit: 500,
                },
            });
            return records.length
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the categories: ' + error.message)
        }
    };

    // crear categoria
    const create_category = async (params) => {
        try {
            const response = await Moralis.Cloud.run("CreateCategory", {
                displayName: params._name,
                urlName: params._urlName,
                image: params._image,
                bannerImg: params._bannerImg,
                description: params._description,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context creating a new category: ' + error.message)
        }
    };
    // editar una categoria
    const edit_category = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("EditCategory", {
                urlName: filter._urlName,
                newName: filter._newName,
                newImage: filter._newImage,
                newDescription: filter._newDescription,
                newBanner: filter._newBanner,
                newOrder: filter._newOrder,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error(`Error editing the category with id: ${filter._urlName}`);
        }
    };

    return (
        <CategoriesContext.Provider value={{
            get_category,
            get_all_categories_cms,
            get_all_categories_length_cms,
            create_category,
            edit_category,
        }}>
            {children}
        </CategoriesContext.Provider>
    );
};

export const useCategories = () => {
    const context = React.useContext(CategoriesContext);
    return context;
};