import * as React from 'react';
import ModalCard from "../ModalCard";
import { Typography, CircularProgress, Divider } from "@mui/material";

const WaitingModal = ({ _open, _handleClose, _title, _subtitle }) => {

    return (
        <ModalCard className="modal-properties waiting-modal-properties" open={_open} handleClose={_handleClose}>
            <Typography type="blockquoteRegular">
                {_title}
            </Typography>

            <Divider className={"divider mt-3"} />

            <div className="modal-container">
                <CircularProgress color="info" sx={{ marginBottom: '1.5rem' }} />
                <Typography className={"modal-text"}>
                    {_subtitle}
                </Typography>
            </div>
            <div className={"button-section mt-2"}>
                &nbsp;
            </div>
        </ModalCard>
    )
}

export default WaitingModal