import * as React from 'react'
import { useMoralis } from 'react-moralis';
import './Login.css';
import { useLocation, Navigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import MetamaskModal from '../../components/Modals/MetamaskModal/MetamaskModal';
import logo from '../../assets/DTC_title.svg'
import logo2 from '../../assets/dtc-1.jpg'
// bootstrap
import { Container } from 'react-bootstrap';
// mui
import { Box, Button, AppBar, Toolbar, Typography } from '@mui/material'

const Login = () => {
    const { isAuthenticated } = useMoralis();
    const location = useLocation();


    // boton de conectar
    const { connect_wallet } = useUser();
    const [noMetamask, setNoMetamask] = React.useState(false)
    const connectWallet = async () => {
        try {
            const { ethereum } = window;
            if (!ethereum) {
                setNoMetamask(true);
                throw new Error("Metamask not installed");
            }
            await connect_wallet();
        } catch (error) {
            console.error(error);
            throw new Error("Error en el login: " + error.message);
        }
    }

    if (isAuthenticated) return <Navigate to="/" state={{ from: location }} replace />
    return (
        <Container fluid className="bg-login d-flex justify-content-center align-items-center">
            <AppBar position="fixed" open={true} className="shadow-none" color="info">
                <Toolbar className="d-flex align-items-start justify-content-between" sx={{ paddingLeft: '5rem', paddingTop: '2rem', minHeight: '7vh !important' }}>
                    <div className="d-flex align-items-center">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <img src={logo} alt="logo" />
                    </div>
                </Toolbar>
            </AppBar >
            <Box component="span" className="loginBox"
                sx={{ width: 380, height: 500, backgroundColor: "white", borderRadius: "5%", boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32)" }}>
                <img src={logo2} alt="logo 2" height="180px" />
                <Typography variant="subtitle2" component={"h2"} color="primary" className="mx-4 text-center" sx={{ fontSize: '2rem', lineHeight: '1.25'}}>
                    Welcome to the admin panel
                </Typography>
                <MetamaskModal open={noMetamask} handleClose={() => setNoMetamask(false)} />
                <Button className="login" component="span" variant="contained" color="primary" onClick={connectWallet}
                    sx={{
                        fontSize: "1.3rem",
                        padding: "0.75rem",
                        minWidth: "180px",
                        borderRadius: "1rem",
                        boxShadow:
                            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                    }}
                >
                    LOGIN
                </Button>
            </Box>
        </Container>
    )
}

export default Login