/* eslint-disable new-parens */
import * as React from "react";
import Moralis from 'moralis-v1';
import { ethers } from 'ethers';
import { useNetwork } from './NetworkContext';
import { erc1155Abi } from '../utils/erc1155Contract';

const DTCsContext = React.createContext([]);

DTCsContext.displayName = "DTCsContext";

export const DTCsProvider = ({ children }) => {
    const { getMaxGas } = useNetwork();
    // obtener dtcs con todos los filtros disponibles
    const get_dtcs = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // Filtros
                collection: filter._collection,
                state: filter._state,
                creator: filter._creator,
                owner: filter._owner,
                minPrice: filter._minPrice,
                maxPrice: filter._maxPrice,
                collectionAddress: filter._collectionAddress,
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: filter._quantity,  // cantidad de randoms a traer
                // Paginacion
                offset: filter._offset,
                limit: filter._limit,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // se saltea los formatos => solo devuelve el largo del array
                onlyLength: filter._onlyLength,
            });
            return response
        } catch (error) {
            throw new Error('Error getting the DTCs: ' + error)
        }
    };

    // obtener un solo dtc de muchas maneras
    const get_dtc = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // filtros
                moralisId: filter._moralisId,
                tokenId: filter._tokenId,
                collectionAddress: filter._collectionAddress,
                marketplaceId: filter._marketplaceId,
                addCollection: filter._addCollection,
                addCategory: filter._addCategory,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: 1,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // al ser uno solo siempre va true
                onlyFirst: true,
            });
            return response
        } catch (error) {
            throw new Error('Error getting the DTC: ' + error)
        }
    };

    const isInFavorites = async (_moralisId) => {
        try {
            const response = await Moralis.Cloud.run("IsInFavorites", { tokenId: _moralisId })
            return response
        } catch (error) {
            console.error(error);
        }
    }

    // obtener solo la cantidad de dtcs
    const get_dtcs_length = async (filter) => {
        try {
            const response = await Moralis.Cloud.run("GetDTCs", {
                // Filtros
                collection: filter._collection,
                state: filter._state,
                creator: filter._creator,
                owner: filter._owner,
                minPrice: filter._minPrice,
                maxPrice: filter._maxPrice,
                collectionAddress: filter._collectionAddress,
                // listo para ser minteado
                readyToMint: filter._readyToMint,
                // random
                getRandom: filter._getRandom, // true or false
                quantity: filter._quantity,  // cantidad de randoms a traer
                // Paginacion
                offset: filter._offset,
                limit: filter._limit,
                // Ordenamiento
                sortBy: filter._sortBy,
                asc: filter._asc,
                // Busqueda 
                search: filter._search,
                // se saltea los formatos => solo devuelve el largo del array
                onlyLength: filter._onlyLength,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error in the context getting all the Collections: ' + error.message)
        }
    };

    // crear DTC en la db
    const create_dtc = async (params) => {
        try {
            const response = await Moralis.Cloud.run("UploadDTCs", {
                name: params._name,
                image: params._image,
                json: params._json,
                jsonObj: params._jsonObj,
                creator: params._creator,
                timestamp: params._timestamp,
                signature: params._signature,
                collection: params._collection,
                collectionAddress: params._collectionAddress,
                description: params._description,
                state: params._state,
                totalSupply: params._totalSupply,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error uploading the DTC in the db: ' + error.message);
        }
    };

    // editar datos de un DTC en la db
    const edit_dtc = async (params) => {
        try {
            await Moralis.Cloud.run("EditDTCs", {});
        } catch (error) {
            console.error(error);
            throw new Error('Error editing the DTC in the db: ' + error.message);
        }
    };

    // mintear dtc
    // @params: collectionAddress, json, tokenId, amount
    const mint_dtc = async (params, _amount) => {
        try {
            const { ethereum } = window;
            if (!ethereum) return
            const fastestGas = await getMaxGas();
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const myAddress = await signer.getAddress();
            let contract = new ethers.Contract(params._collectionAddress, erc1155Abi, signer);
            const tx = await contract.mint(myAddress, params._tokenId, _amount, 65535, params._json, "0x", { gasPrice: fastestGas, gasLimit: 700000 });
            await tx.wait();
            return { success: true }
        } catch (error) {
            console.error(error);
            return { success: false, error: error.message }
        }
    };

    const put_on_sale = async (params) => {
        try {
            const response = await Moralis.Cloud.run("sellDTC", {
                moralisId: params._moralisId,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error editing the DTC in the db: ' + error.message);
        }
    };

    const put_as_sold = async (params) => {
        try {
            const response = await Moralis.Cloud.run("sellDTC", {
                moralisId: params._moralisId,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error editing the DTC in the db: ' + error.message);
        }
    };

    const get_favorites = async (_states) => {
        try {
            const favedDTCs = await Moralis.Cloud.run("GetFavs", { states: _states });
            return favedDTCs
        } catch (error) {
            console.error(error);
        }
    };

    const addToFavorites = async (_thisDTC) => {
        if (!_thisDTC) return

        try {
            const response = await Moralis.Cloud.run("SetAsFavorite", {
                tokenId: _thisDTC,
            })
            console.log('respuesta: ', response)
            return response.success
        } catch (error) {
            console.error(error);
        }
    }

    const create_template = async (params) => {
        try {
            console.log(params)
            const response = await Moralis.Cloud.run("UploadCampaign", {
                name: params._name,
                collectionAddress: params._collectionAddress,
                maxSupply: params._maxSupply,
                templateUri: params._templateUri,
            });
            return response
        } catch (error) {
            console.error(error);
            throw new Error('Error uploading the DTC in the db: ' + error.message);
        }                                       
    }

    const changeOrder = async (_thisDTC, _newOrder) => {
        if (!_thisDTC) return;

        try {
            const response = await Moralis.Cloud.run("SetOrder", {
                moralisId: _thisDTC.id,
                order: _newOrder
            });

            if (!response.success) throw new response.error

            return response.success
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DTCsContext.Provider value={{
            get_dtcs,
            get_dtc,
            create_dtc,
            edit_dtc,
            get_dtcs_length,
            mint_dtc,
            put_on_sale,
            isInFavorites,
            addToFavorites,
            get_favorites,
            changeOrder,
            put_as_sold,
            create_template,
        }}>
            {children}
        </DTCsContext.Provider>
    );
};

export const useDTCs = () => {
    const context = React.useContext(DTCsContext);
    return context;
};