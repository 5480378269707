import * as React from 'react';
import Moralis from 'moralis-v1';
import { ethers } from 'ethers';
import { Container } from 'react-bootstrap';
// tabla
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { IconButton, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'var(--primary-main)',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--primary-main)',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [totalRoyalties, setTotalRoyalties] = React.useState(0);
    React.useEffect(() => {
        for (const share of row.attributes.shares) {
            setTotalRoyalties((prevState) => (prevState + Number(ethers.utils.formatEther(share))))
        }
    }, [])

    return (
        <React.Fragment>
            <StyledTableRow>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.attributes.name}
                </StyledTableCell>
                <StyledTableCell align="center">{row.attributes.address}</StyledTableCell>
                <StyledTableCell align="center">
                    <Button variant="outlined" onClick={() => props.action(row.attributes.address, totalRoyalties)}>
                        Use this contract
                    </Button>
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Container>
                            <Table size="small" aria-label="payees">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ backgroundColor: 'white !important', color: 'var(--primary-main) !important' }}>Beneficiary address</StyledTableCell>
                                        <StyledTableCell sx={{ backgroundColor: 'white !important', color: 'var(--primary-main) !important' }} align="center">Shares</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.attributes.payees.length > 0
                                        && row.attributes.payees.map((beneficiary, index) => {
                                            return <StyledTableRow key={beneficiary}>
                                                <StyledTableCell>
                                                    {beneficiary}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {ethers.utils.formatEther(row.attributes.shares[index])} %
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        })}
                                    <StyledTableRow></StyledTableRow>
                                </TableBody>
                            </Table>
                        </Container>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}


const SplittersTable = ({ action }) => {

    // todos los splitters que esten en la db
    const [allSplitters, setAllSplitters] = React.useState([])
    const get_splitters = async () => {
        try {
            const response = await Moralis.Cloud.run("GetAllSplitters", {});
            console.log(response)
            setAllSplitters(response)
        } catch (error) {
            console.error(error)
        }
    };
    React.useEffect(() => {
        get_splitters()
    }, []);


    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>Contract Name</StyledTableCell>
                        <StyledTableCell align="center">Contract address</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allSplitters.length > 0 &&
                        allSplitters?.map((row) => <Row key={row.attributes.address} row={row} action={action} />)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SplittersTable