import React from "react";
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Typography, IconButton, Tooltip } from '@mui/material';
import editCard from '../../assets/edit_collection.png';
import { Link, useNavigate } from "react-router-dom";

const CardStyled = styled(Card)`
  border-radius: 15px;
  --card-min-width: 350px;
  --card-max-width: 365px;
  height: 320px;
  min-width: var(--card-min-width);
  max-width: var(--card-max-width);
  background: var(--primary-main);
  color: #FFFFFF;
  margin-bottom: 30px;
`;

const CustomIconButton = styled(IconButton)`
  padding: 0;
  margin-left: calc(3*var(--card-max-width)/4);
`;

const CollectionImage = styled("img")`
  border-radius: 50%;
  --width: 60px;
  width: var(--width);
  height: var(--width);
  margin-top: -30px;
`;

const CollectionTitle = styled("label")`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: -5px;
`;

const CollectionsCard = ({ _name, _img, _slug, _collection = false }) => {
    let navigate = useNavigate();

    return (
        <CardStyled className="hover-up" style={{ marginRight: '1rem' }}>
            <Link to={_collection ? `/panel/${_slug}` : `/panel/category/${_slug}`}>
                <CardMedia
                    component="img"
                    height='240'
                    image={_img.url}
                    alt={_name}
                    sx={{ backgroundColor: '#FFFFFF' }}
                />
            </Link>
            <CardContent style={{ padding: '0px', paddingBottom: '5px' }}>
                {_collection
                    ? <Tooltip title="Config collection" arrow>
                        <CustomIconButton onClick={() => navigate(`/panel/${_slug}/edit`)}>
                            <CollectionImage src={editCard} alt={"Config collection"} />
                        </CustomIconButton>
                    </Tooltip>

                    : <Tooltip title="Config category" arrow>
                        <CustomIconButton onClick={() => navigate(`/panel/category/${_slug}/edit`)}>
                            <CollectionImage src={editCard} alt={"Config category"} />
                        </CustomIconButton>
                    </Tooltip>
                }

                <Link to={_collection ? `/panel/${_slug}` : `/panel/category/${_slug}`}>
                    <CollectionTitle>
                        <Typography gutterBottom variant="button" component="div" className="typo p-0 m-0" fontSize={'inherit'}>
                            {_name}
                        </Typography>
                    </CollectionTitle>
                </Link>
            </CardContent>
        </CardStyled >
    );
};

export default CollectionsCard