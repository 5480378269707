import * as React from 'react';
// react-router-dom
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// layout
import Layout from './layout/Layout'
// publicas
import Login from './pages/public/Login'
// solo si estas logueado
import RequireConnected from './pages/private/RequireConnected'
import Welcome from './pages/private/Welcome/Welcome';
// solo administradores
import RequireAdminRole from './pages/private/RequireAdminRole';
import AllCategories from './pages/private/Admin/AllCategories';
import CreateCategories from './pages/private/Admin/CreateCategories';
import Category from './pages/private/Admin/Category';
import CreateCollection from './models/CreateCollection/CreateCollection';
import Collection from './pages/private/Admin/Collection';
import DTCsDetail from './pages/private/DTCsDetail';
import RolesAdmin from './pages/private/Admin/RolesAdmin';
import CreateCampaigns from './pages/private/Admin/CreateCampaign';

// solo artistas
import RequireArtistRole from './pages/private/RequireArtistRole';
import CreateDTCs from './pages/private/Artist/CreateDTCs';
import DTCsGalleryAdmin from './pages/private/Admin/DTCsGalleryAdmin';
import DTCsGalleryArtist from './pages/private/Artist/DTCsGalleryArtist';
import CategoryConfig from './pages/private/Admin/CategoryConfig';
import CollectionConfig from './pages/private/Admin/CollectionConfig';
import ContractsAddresses from './pages/private/Admin/ContractsAddresses';
import Dashboard from './pages/private/Admin/Dashboard';
import CollectorConfig from './pages/private/Admin/CollectorConfig';

function App() {
  const location = useLocation();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<RequireConnected />} >
          <Route index element={<Navigate to="/panel" state={{ from: location }} replace />} />
          <Route path="panel" element={<Welcome />} />

          <Route path="panel/:address" element={<Collection />} />
          <Route path="panel/:address/edit" element={<CollectionConfig />} />
          <Route path="panel/:address/:slug" element={<DTCsDetail />} />
          <Route path="panel/create-dtc" element={<CreateDTCs />} />

          {/* solo owner o admin */}
          <Route element={<RequireAdminRole />}>
            <Route path="panel/categories" element={<AllCategories />} />
            <Route path="panel/create-category" element={<CreateCategories />} />
            <Route path="panel/category/:category" element={<Category />} />
            <Route path="panel/category/:category/edit" element={<CategoryConfig />} />
            <Route path="panel/:category/create-collection" element={<CreateCollection />} />
            <Route path="panel/admin/roles" element={<RolesAdmin />} />
            <Route path="panel/admin/gallery" element={<DTCsGalleryAdmin />} />
            <Route path="panel/admin/collector" element={<CollectorConfig />} />
            <Route path="panel/admin/contracts" element={<ContractsAddresses />} />
            <Route path="panel/admin/create-campaign" element={<CreateCampaigns />} />
            <Route path="panel/admin/dashboard" element={<Dashboard />} />
          </Route>

          {/* solo artista */}
          <Route element={<RequireArtistRole />}>
            <Route path="panel/artist/dtcs" element={<DTCsGalleryArtist />} />
          </Route>

        </Route>
      </Route>
      {/** LOGIN **/}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<><h1>404</h1><h3>Not found</h3></>} />
    </Routes>
  );
}

export default App;
