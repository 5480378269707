/* eslint-disable no-throw-literal */
import * as React from "react";
import "./CreateCategory.css";
// modales
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
// Formulario
import { Row, Col } from "react-bootstrap";
import { Button, Typography } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image';
import BootstrapInput from "../../components/Inputs/BootstrapInput";
// reducers
import { categoryReducer, INITIAL_STATE } from "./categoryReducer";
import useUploading from "../../hooks/useUploading";
// CMS
import { useCMS } from '../../context/CMSContext';

const CreateCategory = () => {
    const { cmsClient } = useCMS();

    // formulario reducer
    const [state, dispatch] = React.useReducer(categoryReducer, INITIAL_STATE);
    const handleInputChange = e => {
        dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value },
        })
    };
    const handleImgChange = e => {
        if (e.target.files && e.target.files.length > 0) {
            dispatch({
                type: "SET_IMAGE",
                payload: { name: e.target.name, value: e.target.files[0] },
            })
        }
    };

    // custom hooks y reducers juntos
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);


    const handleSubmit = (e) => {
        e.preventDefault();
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        uploadToCMS(false);
    };

    // subida a dato CMS
    const uploadToCMS = async (_changeSlug) => {
        try {
            let slug;
            if (!_changeSlug) {
                slug = (state.name.replace(/[^a-zA-Z]/g, '-')).toLowerCase();
            } else {
                slug = (state.name.replace(/[^a-zA-Z]/g, '-')).toLowerCase() + '-v2';
            }
            // subo las imagenes
            const image = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.logoImg,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-logo.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} logo`,
                        title: `${state.name} logo`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });
            const banner = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.bannerImg,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-banner.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} banner`,
                        title: `${state.name} banner`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });
            const darkIcon = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.darkIcon,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-darkIcon.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} dark icon`,
                        title: `${state.name} dark icon`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });
            const lightIcon = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.lightIcon,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-lightIcon.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} light icon`,
                        title: `${state.name} light icon`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });
            // se crea una nueva categoria en CMS
            const newCategory = await cmsClient.items.create({
                item_type: { type: 'item_type', id: '241678' },
                name: state.name,
                slug: slug,
                description: state.description,
                cover_image: { upload_id: image.id },
                light_icon: { upload_id: lightIcon.id },
                dark_icon: { upload_id: darkIcon.id },
                destination: `/category/${slug}`,
                banner_image: { upload_id: banner.id },
            });
            
            const publishItem = await cmsClient.items.publish(newCategory.id, {
                recursive: 'true'
            });
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
        } catch (error) {
            if ((error[0].attributes.details.code === "VALIDATION_UNIQUE") && (error[0].attributes.details.field === "slug")) {
                uploadToCMS(true)
            }
            console.error(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Creating category"} _subtitle={`The category ${state.name} is being created. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Category created"} _subtitle={`The category ${state.name} has been created and successfully uploaded!`} _redirect={`/panel/categories`} />
            {/* Cover image */}
            <Row className="mt-3 mb-5">
                <Col className="px-0">
                    <label style={{ 'border': state.logoImg ? 'none' : '', 'justifyContent': state.logoImg ? 'flex-start' : 'center' }}
                        htmlFor="logoImg" className="custom-upload" >
                        {!state.logoImg
                            ? <>
                                <Row className="justify-content-center"><ImageIcon color="disabled" sx={{ fontSize: '8rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} /></Row>
                                <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Cover image</Typography></Row>
                            </>
                            : <img src={URL.createObjectURL(state.logoImg)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="logoImg" id="logoImg" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            {/* Icons */}
            <Row className="mt-3 mb-5">
                <Col className="px-0">
                    <label style={{ 'border': state.lightIcon ? 'none' : '', 'justifyContent': state.lightIcon ? 'flex-start' : 'center' }}
                        htmlFor="lightIcon" className="custom-upload" >
                        {!state.lightIcon
                            ? <>
                                <Row className="justify-content-center"><ImageIcon color="disabled" sx={{ fontSize: '8rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} /></Row>
                                <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Light Icon</Typography></Row>
                            </>
                            : <img src={URL.createObjectURL(state.lightIcon)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="lightIcon" id="lightIcon" onChange={handleImgChange} />
                    </label>
                </Col>
                <Col className="px-0">
                    <label style={{ 'border': state.darkIcon ? 'none' : '', 'justifyContent': state.darkIcon ? 'flex-start' : 'center' }}
                        htmlFor="darkIcon" className="custom-upload" >
                        {!state.darkIcon
                            ? <>
                                <Row className="justify-content-center"><ImageIcon color="disabled" sx={{ fontSize: '8rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} /></Row>
                                <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Dark Icon</Typography></Row>
                            </>
                            : <img src={URL.createObjectURL(state.darkIcon)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="darkIcon" id="darkIcon" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            {/* Banner */}
            <Row className="my-5">
                <Col className="px-0">
                    <label style={{ 'border': state.bannerImg ? 'none' : '', 'justifyContent': state.bannerImg ? 'flex-start' : 'center' }} htmlFor="bannerImg" className="custom-banner-upload">
                        {!state.bannerImg
                            ? <>
                                <Row className="justify-content-center"><ImageIcon color="disabled" sx={{ fontSize: '8rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} /></Row>
                                <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Banner image</Typography></Row>
                            </>
                            : <img src={URL.createObjectURL(state.bannerImg)} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '220px', borderRadius: '13px' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="bannerImg" id="bannerImg" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>

            {/* Name */}
            <Row className="my-5">
                <BootstrapInput name="name" className="p-0" placeholder="Category name:" type="text" onChange={handleInputChange} />
            </Row>
            {/* Description */}
            <Row className="my-5">
                <BootstrapInput name="description" placeholder="Category description:" type="text" multiline rows="4" onChange={handleInputChange} />
            </Row>
            <Row className="my-3">
                <Col md={8} lg={4} className="ps-0">
                    <Button type="submit" disabled={!(state.name !== '' && state.description !== '' && state.logoImg && state.bannerImg) || (waitState.loading)} color="secondary"
                        variant="contained" onClick={handleSubmit} sx={{ fontSize: '1.25rem' }}>
                        Create Category
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default CreateCategory;
