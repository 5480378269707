import * as React from 'react';
import { Card } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const CardStyled = styled(Card)`
  border-radius: 15px;
  --card-min-width: 250px;
  --card-max-width: 260px;
  --card-min-height: 370px;
  --card-max-height: 380px;
  min-width: var(--card-min-width);
  max-width: var(--card-max-width);
  min-height: var(--card-min-height);
  max-height: var(--card-max-height);
  background: #FFFFFF;
  color: var(--primary-main);
  margin-bottom: 30px;
`;

const AddDTCsCards = () => {
    return (
        <Link to={'/panel/create-dtc'}>
            <CardStyled className="hover-up" style={{ margin: '1rem' }}>
            <div className="d-flex align-items-center justify-content-center hover-up add-dtc">
                <IconButton className="d-flex flex-column">
                    <AddCircleOutlineIcon sx={{ marginBottom: '1rem' }} />
                    <p>Create DTC</p>
                </IconButton>
            </div>
            </CardStyled>
        </Link>
    )
}

export default AddDTCsCards