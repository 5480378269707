import * as React from "react";
import axios from "axios";
import Moralis from 'moralis-v1';
import { ethers } from "ethers";
import { useNetwork } from './NetworkContext';
import { erc1155Abi } from '../utils/erc1155Contract';



const CampaignContext = React.createContext([]);

CampaignContext.displayName = "CampaignContext";

export const CampaignProvider = ({ children }) => {

    const { getMaxGas } = useNetwork();
        
    const getContactList = async () => {
        try {
            const response = await Moralis.Cloud.run("GetContactList");
            console.log(response.data);
            return response.data
        } catch (error) {
            console.log(error);
        }  
    }

    // const getEstimatedCost = async (amount, address, maxSupply, uri) => {
    //     const { ethereum } = window;
    //         if (!ethereum) return
    //         const fastestGas = await getMaxGas();
    //         const provider = new ethers.providers.Web3Provider(ethereum);
    //         const signer = provider.getSigner();
    //         const contract = new ethers.Contract(address, erc1155Abi, signer);
    //         const tx = await contract.estimateGas.primaryMint(address, 1, amount, maxSupply, uri, "0x", { gasPrice: fastestGas, gasLimit: 700000 });

    // }

    return (
        <CampaignContext.Provider value={{
            getContactList,
        }}>
            {children}
        </CampaignContext.Provider>
    );
}

export const useCampaign = () => {
    const context = React.useContext(CampaignContext);
    return context;
};