import * as React from 'react'
import { ethers } from 'ethers';
import { Container, Row } from 'react-bootstrap';
import { rolesAddress, rolesAbi } from '../../utils/rolesContract'
import { Button, Typography } from '@mui/material'
import BootstrapInput from '../../components/Inputs/BootstrapInput';
// modales
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import useUploading from "../../hooks/useUploading";
import { useNetwork } from '../../context/NetworkContext';

const CreateNewRole = () => {
    // Variables
    const [newRole, setNewRole] = React.useState("");
    // custom hooks y reducers juntos
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);

    // Máximo gas
    const { getMaxGas } = useNetwork();

    const create_role = async () => {
        console.log(newRole)
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        try {
            const { ethereum } = window;
            if (!ethereum) throw new Error("No Metamask");
            // Conecto la wallet
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            let contract = new ethers.Contract(rolesAddress, rolesAbi, signer);
            // Obtenemos el maximo gas del momento
            let fastestGas = await getMaxGas();
            const tx = await contract.createRole(newRole, { gasPrice: fastestGas });
            await tx.wait()
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };

    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Creating role"} _subtitle={`A new role is being created. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Role created"} _subtitle={`A new role has been successfully created!`} _redirect={`/panel/admin/roles`} />
            <Container fluid>
                <Row className="my-1 w-75">
                    <Typography variant="h6" component="h3" color="primary" className="my-2 px-0">New role name: </Typography>
                    <BootstrapInput className="w-100 px-0" type="text" value={newRole} placeholder="New role name" onChange={(e) => setNewRole(e.target.value)} />
                </Row>
                {/* button */}
                <Row className="my-4 w-25">
                    <Button onClick={create_role} variant="contained" disabled={(!newRole || newRole === '')}>
                        Create
                    </Button>
                </Row>
            </Container>
        </>
    )
}

export default CreateNewRole