import * as React from 'react';
import { Container } from "react-bootstrap";
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import CreateDTC from '../../../models/CreateDTC/CreateDTC';
import { useQuery } from 'graphql-hooks';
import Loading from "../../../components/Loading/Loading";

const CreateDTCs = () => {
  const categories = useQuery(`query MyQuery {
    allCategories {
          id
          name
          slug
          destination
          lightIcon {
            url
          }
          darkIcon {
            url
          }
          coverImage {
            url
          }
          bannerImage {
            url
          }
      }
  }`);

  if (categories.loading) return <Loading />

  return (
    <>
      <Container>
        <GoBack />
        <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-2">Create DTCs</Typography>
        <CreateDTC _categories={categories?.data.allCategories} />
      </Container>
    </>
  )
}

export default CreateDTCs