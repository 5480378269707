import * as React from 'react';
import { useQuery } from "graphql-hooks";
import { useParams } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import EditCategory from '../../../models/EditCategory/EditCategory';
import Loading from "../../../components/Loading/Loading";

const CategoryConfig = () => {
  let { category } = useParams();
  const thisCategory = useQuery(`query MyQuery {
      category(filter: {slug: {eq: ${category}}}) {
        name
        bannerImage {
          url
        }
        coverImage {
          url
        }
        id
        description
        lightIcon {
          url
        }
        darkIcon {
          url
        }
      }
  }`);

  if (thisCategory.loading) return <Loading />

  return (
    <Container>
      <GoBack />
      <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1 mb-3">Edit {thisCategory?.data.category.name} category</Typography>
      <EditCategory _category={thisCategory.data.category} />
    </Container>
  )
}

export default CategoryConfig