import * as React from 'react';
import { ethers } from 'ethers';
import { useParams } from 'react-router-dom';
import { rolesAddress } from '../../utils/rolesContract';
import { factoryAbi, factoryAddress } from '../../utils/factoryContract';
// layout
import { Container, Row, Col } from 'react-bootstrap';
// componentes
import GoBack from '../../components/GoBack/GoBack'
import HelperIcon from '../../components/HelperIcon/HelperIcon';
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import WaitingModal from '../../components/Modals/WaitingModal/WaitingModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import SplittersTable from '../../components/SplittersTable/SplittersTable';
// Tabs Properties
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// hooks
import { useIPFS } from '../../context/IPFSContext'
import { useNetwork } from '../../context/NetworkContext'
import { useCollections } from '../../context/CollectionsContext';
import useUploading from "../../hooks/useUploading";
import { collectionReducer, COLLECTION_INITIAL_STATE, ACTION_TYPE } from './collectionReducer';
// mui
import { Button, InputAdornment, Typography, Switch, IconButton, Select, MenuItem } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// CMS
import { buildClient } from '@datocms/cma-client-browser';
import { useQuery } from 'graphql-hooks';


const CreateCollection = () => {
  const { category } = useParams();
  const thisCategory = useQuery(`query MyQuery {
    category(filter: {slug: {eq: ${category}}}) {
      name
      bannerImage {
        url
      }
      coverImage {
        url
      }
      id
    }
  }`);
  const { getMaxGas } = useNetwork();
  const { create_collection } = useCollections();
  const { upload_image, upload_json } = useIPFS();
  // pasos en crear coleccion
  const [step, setStep] = React.useState(1);
  // formulario reducer
  const [state, dispatch] = React.useReducer(collectionReducer, COLLECTION_INITIAL_STATE);
  const handleInputChange = e => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    })
  };
  const handleImgChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch({
        type: "SET_IMAGE",
        payload: { name: e.target.name, value: e.target.files[0] },
      })
    }
  };
  const handleSwitchChange = e => {
    dispatch({
      type: "CHANGE_SWITCH",
      payload: { name: e.target.name, value: e.target.checked },
    })
  };
  // custom hooks y reducers
  const wait = useUploading();
  const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);
  // tabs
  const [tabValue, setTabValue] = React.useState("0");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // splitters
  const [splitterName, setSplitterName] = React.useState("");
  // select old splitter
  const [splitterAddress, setSplitterAddress] = React.useState("");
  const [splitterRoyalties, setSplitterRoyalties] = React.useState();
  const setOldSplitter = (_address, _royalties) => {
    setSplitterAddress(_address);
    setSplitterRoyalties(_royalties);
  };
  // royalties
  const checkRoyalties = (evt, _index) => {
    const regex = /^[0-9]*/;
    var isValid = regex.test(evt.target.value);
    if (evt.target.value > 15 || evt.target.value < 0 || !isValid || !evt.target.value) console.log(false)

    dispatch({
      type: ACTION_TYPE.CHANGE_ROYALTY,
      payload: { index: _index, value: evt.target.value, name: evt.target.name },
    })
  };
  const addRoyalty = () => {
    const newRoyalty = { beneficiary: '', shares: 0, id: Date.now() }
    dispatch({
      type: ACTION_TYPE.ADD_ROYALTY,
      payload: { name: 'royalties', value: newRoyalty },
    })
  };
  const removeRoyalty = (_id) => {
    dispatch({
      type: ACTION_TYPE.REMOVE_ROYALTY,
      payload: { name: 'royalties', value: _id },
    })
  };
  const handleAddressChange = (evt, _index) => {
    dispatch({
      type: ACTION_TYPE.CHANGE_ROYALTY,
      payload: { index: _index, value: evt.target.value, name: evt.target.name },
    })
  };

  // funciones
  const handleSubmit = async e => {
    e.preventDefault();
    waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
    if (!state.royaltiesSwitch) {
      uploadToIPFS(factoryAddress, 0);
    } else if (tabValue === "0") {
      uploadToIPFS(splitterAddress, (splitterRoyalties * 100));
    } else if (tabValue === "1") {
      createSplitter();
    } else {
      console.error('Error setting the splitter');
    }
  };
  // crear payment splitter
  const createSplitter = async () => {
    let payees = [];
    let shares = [];
    let royalties = 0;
    for (const _royalty of state.royalties) {
      payees.push(_royalty.beneficiary);
      shares.push(ethers.utils.parseEther(_royalty.shares));
      royalties += Number(_royalty.shares);
    }
    try {
      const { ethereum } = window;
      if (!ethereum) throw new Error('No Metamask!');
      const fastestGas = await getMaxGas();
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      let contract = new ethers.Contract(factoryAddress, factoryAbi, signer);
      const tx = await contract.createPaymentSplitter(payees, shares, splitterName, { gasPrice: fastestGas });
      const response = await tx.wait();
      console.log(`Splitter address: ${response.logs[0].address}`);
      uploadToIPFS(response.logs[0].address, (royalties * 100))
    } catch (error) {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
    }
  };
  // subir a IPFS
  const uploadToIPFS = async (_splitterAddress, _royalties) => {
    try {
      // imagenes
      const logoImg = await upload_image(state.logoImg);

      // metadata que va a ir a opensea
      const contractMetadataJSON = {
        "name": state.name,
        "description": state.description,
        "image": `https://digitaltradingcards.mypinata.cloud/ipfs/${logoImg}`,
        "external_link": "https://digitaltradingcards.com",
        "fee_recipient": _splitterAddress,
        "seller_fee_basis_points": _royalties,
      };
      const openseaMetadata = await upload_json(contractMetadataJSON)
      // si no se revela => se suben la imagen de revelado y su metadata
      if (!state.reveal) {
        const revealImg = await upload_image(state.revealImg);
        // metadata del dtc que se muestra antes del revelado
        const revealJSON = {
          "name": `${state.name} DTC`,
          "description": `DTC from the ${state.name} collection. Soon will be revealed`,
          "image": `https://digitaltradingcards.mypinata.cloud/ipfs/${revealImg}`,
        };
        var revealMetadata = await upload_json(revealJSON);
      };

      createCollection(openseaMetadata, state.reveal ? 'Already revealed' : revealMetadata, _splitterAddress, _royalties)

    } catch (error) {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
    }
  };
  // crear coleccion
  const createCollection = async (_openseaMetadata, _revealMetadata, _splitterAddress, _royalties) => {
    try {
      const { ethereum } = window;
      if (!ethereum) throw new Error('No Metamask!')
      const fastestGas = await getMaxGas();
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const myAddress = await signer.getAddress();
      let contract = new ethers.Contract(factoryAddress, factoryAbi, signer);

      if (state.type === '721') {
        //   struct Params {
        //     string _name;
        //     string _symbol;
        //     address _rolesContract;
        //     string _contractUri;
        //     uint256 _lazyMintingPrice;
        //     uint96 _maxDTCsPerWallet;
        //     bool _revealed;
        //     string _revealUrl;
        //     uint256 _royalty;
        //     address _paymentSplitter;
        // }
        const tx = await contract.createCollection721([
          state.name,
          rolesAddress,
          _openseaMetadata,
          ethers.utils.parseEther(state.price),
          state.maxPerWallet,
          state.reveal,
          _revealMetadata,
          state.symbol,
          _royalties,
          _splitterAddress,
        ], { gasPrice: fastestGas });

        const response = await tx.wait();

        uploadToCMS(response.logs[0].address, myAddress, _revealMetadata, _openseaMetadata, false);

      } else {
        //   struct Params {
        //     string _name;
        //     address _rolesContract;
        //     string _contractUri;
        //     uint256 _lazyMintingPrice;
        //     uint256 _maxDTCsPerWallet;
        //     bool _revealed;
        //     string _revealUrl;
        //     uint256 _royaltyFee;
        //     address _splitterAddress;
        // }

        console.log('royalties', _royalties)
        console.log('splitter', _splitterAddress)
        console.log('price', ethers.utils.parseEther(state.price))
        console.log('revealed', state.reveal)
        console.log('name', state.name)
        console.log('rolesAddress', rolesAddress)
        console.log('openseaMetadata', _openseaMetadata)
        console.log('revealMetadata', _revealMetadata)
        

        const tx = await contract.createCollection1155([
          state.name,
          rolesAddress,
          _openseaMetadata,
          ethers.utils.parseEther(state.price),
          // state.maxPerWallet,
          state.reveal,
          _revealMetadata,
          _royalties,
          _splitterAddress,
        ], { gasPrice: fastestGas });

        const response = await tx.wait();

        uploadToCMS(response.logs[0].address, myAddress, _revealMetadata, _openseaMetadata, false);
      }


    } catch (error) {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
    }
  };
  // subida a CMS
  const uploadToCMS = async (_contractAddress, _creatorAddress, _revealMetadata, _openseaMetadata, _changeSlug) => {
    try {
      let slug;
      if (!_changeSlug) {
        slug = (state.name.replace(/[^a-zA-Z]/g, '')).toLowerCase();
      } else {
        slug = (state.name.replace(/[^a-zA-Z]/g, '')).toLowerCase() + '-v2';
      }
      // declaro el CMS
      const client = buildClient({
        apiToken: process.env.REACT_APP_CMS_API_KEY,
        environment: process.env.REACT_APP_CMS_ENVIRONMENT,
      });
      // subo las imagenes
      const cover_image = await client.uploads.createFromFileOrBlob({
        // File object to upload
        fileOrBlob: state.logoImg,
        // if you want, you can specify a different base name for the uploaded file
        filename: `${state.name}-cover.jpeg`,
        // skip the upload and return an existing resource if it's already present in the Media Area:
        skipCreationIfAlreadyExists: true,
        // specify some additional metadata to the upload resource
        author: 'DTC',
        copyright: 'New copyright',
        default_field_metadata: {
          en: {
            alt: `${state.name} cover`,
            title: `${state.name} cover`,
            focal_point: {
              x: 0.3,
              y: 0.6,
            },
            custom_data: {
              watermark: true,
            },
          },
        },
      });
      const banner_image = await client.uploads.createFromFileOrBlob({
        // File object to upload
        fileOrBlob: state.bannerImg,
        // if you want, you can specify a different base name for the uploaded file
        filename: `${state.name}-banner.jpeg`,
        // skip the upload and return an existing resource if it's already present in the Media Area:
        skipCreationIfAlreadyExists: true,
        // specify some additional metadata to the upload resource
        author: 'DTC',
        copyright: 'New copyright',
        default_field_metadata: {
          en: {
            alt: `${state.name} banner`,
            title: `${state.name} banner`,
            focal_point: {
              x: 0.3,
              y: 0.6,
            },
            custom_data: {
              watermark: true,
            },
          },
        },
      });
      // se crea una nueva categoria en CMS
      const newCollection = await client.items.create({
        item_type: { type: 'item_type', id: '323435' },
        name: state.name,
        slug: slug,
        category: thisCategory.data.category.id,
        cover_image: { upload_id: cover_image.id },
        banner_image: { upload_id: banner_image.id },
        description: state.description,
        destination: `/collection/${slug}`,
        contract_address: _contractAddress,
        // aca todo lo que no se setee por contrato
      });

      await client.items.publish(newCollection.id, {
        recursive: 'true'
      });

      uploadToMoralis(_contractAddress, slug, _creatorAddress, _revealMetadata, newCollection.id, _openseaMetadata, thisCategory.data.category.id);

    } catch (error) {
      // if ((error[0].attributes.details.code === "VALIDATION_UNIQUE") && (error[0].attributes.details.field === "slug")) {
      //   uploadToCMS(_contractAddress, _creatorAddress, _revealMetadata, _openseaMetadata, true)
      // } else {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
      // }
    };
  };
  // subida a moralis
  const uploadToMoralis = async (_contractAddress, _slug, _creatorAddress, _revealMetadata, _cmsId, _openseaMetadata, _categoryId) => {
    try {
      const moralisResponse = await create_collection({
        _displayName: state.name,
        _slug: _slug,
        _category: category,
        _categoryId: _categoryId,
        _revealed: state.reveal,
        _revealMetadata: _revealMetadata,
        _contractAddress: _contractAddress,
        _openseaMetadata: _openseaMetadata,
        _price: (ethers.utils.parseEther((state.price))).toString(),
        _creator: _creatorAddress.toLowerCase(),
        _cmsId: _cmsId,
        _type: state.type,
      });
      if (!moralisResponse.success) throw new Error(moralisResponse.error);
      if (moralisResponse.success) waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
    } catch (error) {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
    }
  };

  return (
    <>
      <GoBack />
      <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Collection created"} _subtitle={`The collection ${state.name} has been created and successfully uploaded!`} _redirect={`/panel/category/${category}`} />
      <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Creating collection"} _subtitle={`The collection ${state.name} is being created. Please wait.`} />
      <Container>
        <Typography variant="h4" component="h1" color="primary" className="mt-4 mb-3">Create collection</Typography>
        <form onSubmit={handleSubmit} className="mt-4">
          {/* STEP 1 */}
          <div className={step !== 1 ? "d-none" : ''}>
            {/* Imagen de Portada */}
            <Row className="my-5">
              <Col>
                <label style={{ 'border': state.logoImg ? 'none' : '', 'justifyContent': state.logoImg ? 'flex-start' : 'center' }}
                  htmlFor="logoImg" className="custom-upload">
                  {!state.logoImg
                    ? <>
                      <Row className="justify-content-center">
                        <ImageIcon color="disabled" sx={{ fontSize: '6rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} />
                      </Row>
                      <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Logo</Typography></Row>
                    </>
                    : <img src={URL.createObjectURL(state.logoImg)} width="auto" height="245px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                  <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="logoImg" id="logoImg" onChange={handleImgChange} />
                </label>
              </Col>
            </Row>
            {/* Imagen Banner */}
            <Row className="my-5">
              <Col>
                <label style={{ 'border': state.bannerImg ? 'none' : '', 'justifyContent': state.bannerImg ? 'flex-start' : 'center' }} htmlFor="bannerImg" className="custom-banner-upload">
                  {!state.bannerImg
                    ? <>
                      <Row className="justify-content-center"><ImageIcon color="disabled" sx={{ fontSize: '6rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} /></Row>
                      <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Banner</Typography></Row>
                    </>
                    : <img src={URL.createObjectURL(state.bannerImg)} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '240px', borderRadius: '13px' }} />}
                  <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="bannerImg" id="bannerImg" onChange={handleImgChange} />
                </label>
              </Col>
            </Row>
            {/* Nombre, simbolo y estandar de la coleccion */}
            <Row className="my-5">
              <Col md={6} className="ps-0">
                <BootstrapInput name="name" className="m-0 p-0 w-100" placeholder="Collection name" type="text" onChange={handleInputChange} />
              </Col>
              <Col md={3}>
                <BootstrapInput name="symbol" className="p-0 w-100" placeholder="Collection symbol" type="text" onChange={handleInputChange} />
              </Col>
              <Col md={3} className="pe-0">
                <Select className="m-0" value={state.type} name="type" displayEmpty onChange={handleInputChange} input={<BootstrapInput className="p-0 w-100" />}>
                  <MenuItem disabled dataid="" value="">
                    Select an standard
                  </MenuItem>
                  <MenuItem value={'721'}>ERC-721</MenuItem>
                  <MenuItem value={'1155'}>ERC-1155</MenuItem>
                </Select>
              </Col>
            </Row>
            {/* Categoria */}
            <Row className="my-5">
              <Col md={8} className="ps-0">
                <BootstrapInput name="category" className="m-0 p-0 w-100" defaultValue={`Category: ${category.toUpperCase()}`} disabled type="text" />
              </Col>
            </Row>
            {/* Descripción */}
            <Row className="my-5">
              <BootstrapInput name="description" placeholder="Collection description" type="text" multiline rows="4" onChange={handleInputChange} />
            </Row>
            {/* Precio y Máximo de NFTs por wallet */}
            <Row className="my-5">
              <Col md={6} className="ps-0">
                <BootstrapInput type="number"
                  name="price" className="w-100"
                  endAdornment={<InputAdornment position="end">USD each DTC</InputAdornment>}
                  placeholder="Lazy Minting Price: (USD)"
                  onChange={handleInputChange}
                />
              </Col>
              <Col md={6} className="pe-0">
                <BootstrapInput type="number"
                  name="maxPerWallet" className="w-100"
                  endAdornment={<InputAdornment position="end">Max DTCs per wallet</InputAdornment>}
                  placeholder="Max DTCs per wallet"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            {/* Next step button */}
            <Row className="my-3">
              <Col md={4} className="ps-0">
                <Button type="button" color="secondary" variant="contained"
                  disabled={!(state.name && state.description && state.symbol && state.logoImg && state.bannerImg && (Number(state.price) > 0) && (Number(state.maxPerWallet > 0)))}
                  onClick={() => setStep(prevState => prevState + 1)} sx={{ fontSize: '1.25rem' }}>
                  Continue
                </Button>
              </Col>
            </Row>
          </div>

          {/* STEP 2 */}
          <div className={step !== 2 ? "d-none" : ''}>
            {/* Imagen de Revelado */}
            <>
              <Row className="my-4 justify-content-start align-items-center">
                <HelperIcon _help={'If activated, the DTCs will be revealed at the time of mining. Otherwise, they will be revealed all together when available.'} _placement={"bottom"} />
                <Col>
                  <FormControlLabel
                    control={
                      <Switch checked={state.reveal} onChange={handleSwitchChange} name="reveal" />
                    }
                    sx={{ color: state.reveal ? "var(--primary-main)" : "gray" }}
                    label="Reveal DTCs"
                  />
                </Col>
              </Row>
              {!state.reveal &&
                <label style={{ 'border': state.revealImg ? 'none' : '2px dashed #ccc', 'justifyContent': state.revealImg ? 'flex-start' : 'center' }} htmlFor="revealImg" className="custom-upload">
                  {!state.revealImg
                    ?
                    <div className='d-flex flex-column align-items-center'>
                      <>
                        <Row className="justify-content-center">
                          <ImageIcon color="disabled" sx={{ fontSize: '8rem', margin: '1rem 0px', maxWidth: '340px', borderRadius: '5%' }} />
                        </Row>
                        <Row><Typography className="text-center" variant="h6" sx={{ color: '#999999' }}>Pre-reveal image</Typography></Row>
                      </>
                    </div>
                    : <img src={URL.createObjectURL(state.revealImg)} width="auto" height="200px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '200px', borderRadius: '5%' }} />}
                  <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="revealImg" id="revealImg" onChange={handleImgChange} />
                </label>
              }
            </>

            {/* Royalties */}
            <>
              <Row className="my-4 justify-content-start align-items-center">
                <HelperIcon _help={'If activated, the DTCs will have royalties for every sale.'} _placement={"bottom"} />
                <Col>
                  <FormControlLabel
                    control={<Switch checked={state.royaltiesSwitch} onChange={handleSwitchChange} name="royaltiesSwitch" />}
                    sx={{ color: state.royaltiesSwitch ? "var(--primary-main)" : "gray" }}
                    label="Add royalties"
                  />
                </Col>
              </Row>
              {state.royaltiesSwitch &&
                <TabContext value={tabValue}>
                  {/* Header */}
                  <TabList onChange={handleTabChange} aria-label="properties tab" className="my-4">
                    <Tab label={"Use existing splitter"} value={"0"} className="px-4" />
                    <Tab label={"New payment splitter"} value={"1"} className="px-4" />
                  </TabList>

                  {/* Contenido */}
                  {/* Tabla con todos los splitters ya deployados */}
                  <TabPanel className="d-flex flex-column align-items-center p-0" value={"0"}>
                    <Container>
                      <SplittersTable action={setOldSplitter} />
                    </Container>
                  </TabPanel>
                  {/* Crear nuevo splitter */}
                  <TabPanel className="d-flex flex-column align-items-center p-0" value={"1"}>
                    <Container>
                      <Row className="my-1">
                        <Col md={6}>
                          <BootstrapInput name="Splitter Name" className="m-0 p-0 w-100" placeholder="Splitter Name" type="text" onChange={(e) => setSplitterName(e.target.value)} />
                        </Col>
                      </Row>
                      {state.royalties?.length > 0 && state.royalties?.map((element, index) => (
                        <Row key={element.id} className="w-90 justify-content-between align-items-center my-2">
                          <Col md={3}>
                            <div className="d-flex flex-column w-90 me-5">
                              <BootstrapInput type="number"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                placeholder="Royalty Fee %"
                                onChange={(e) => checkRoyalties(e, index)}
                                name="shares"
                              />
                              {/* {!validRoyalties && royaltyFee !== "" && <div className="error-form">El valor debe ser entre 0 y 15 %</div>} */}
                            </div>
                          </Col>

                          <Col md={8}>
                            <div className="d-flex flex-column w-100">
                              <BootstrapInput type="text" placeholder="Royalty beneficiary address"
                                onChange={(e) => handleAddressChange(e, index)}
                                name="beneficiary"
                              />
                              {/* {errorWallet && payoutAddress !== "" && <div className="error-form">La wallet ingresada no es correcta</div>} */}
                            </div>
                          </Col>
                          <Col md={1}>
                            <IconButton type="button" color="primary" onClick={() => removeRoyalty(index)}><RemoveCircleOutlineIcon /></IconButton>
                          </Col>
                        </Row>
                      ))}
                      <Button type="button" variant="outlined" size="large" sx={{ fontSize: '0.9rem', marginRight: "2rem", marginTop: "1rem" }} onClick={addRoyalty}>
                        <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                        Add new royalties beneficiary
                      </Button>
                    </Container>
                  </TabPanel>
                </TabContext>
              }
            </>

            {/* Create collection button */}
            <Row className="my-5">
              <Col md={4} className="ps-0">
                <Button type="button" color="secondary" variant="contained"
                  // disabled={!((!state.royaltiesSwitch || (ethers.utils.isAddress(state.royaltiesAddresses) && state.validRoyalties)) && (state.reveal)) || (waitState.loading)}
                  onClick={handleSubmit} sx={{ fontSize: '1.25rem' }}>
                  Create collection
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  )
}

export default CreateCollection