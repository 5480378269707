import * as React from 'react';
import ModalCard from "../ModalCard";
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Divider } from "@mui/material";

const ConfirmationModal = ({ _open, _handleClose, _title, _subtitle, _redirect }) => {
    let navigate = useNavigate();
    const routeChange = () => {
        if (_redirect === null) {
            _handleClose();
            return
        }
        if (window.location.pathname === _redirect) {
            window.location.reload()
        } else {
            navigate(_redirect);
        }
    };

    return (
        <ModalCard className="confirmation-modal-properties modal-properties" open={_open} handleClose={_handleClose}>
            <Typography type="blockquoteRegular">
                {_title}
            </Typography>

            <Divider className={"divider mt-3"} />

            <div className="modal-container">
                <Typography className={"modal-text"}>
                    {_subtitle}
                </Typography>
            </div>

            <Divider className={"divider"} />


            <div className={"button-section mt-2"}>
                <Button variant='contained' color="info" onClick={routeChange}>
                    Continue
                </Button>
            </div>
        </ModalCard>
    )
}

export default ConfirmationModal