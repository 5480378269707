import * as React from "react";
import { useMoralis } from "react-moralis";
import Moralis from 'moralis-v1';
import { ethers } from "ethers";
import { rolesAddress, rolesAbi } from '../utils/rolesContract';

const UserContext = React.createContext([]);

UserContext.displayName = "UserContext";

export const UserProvider = ({ children }) => {
    // useMoralis
    const { authenticate, isAuthenticated, logout } = useMoralis();

    // connect to Admin panel
    const connect_wallet = async () => {
        try {
            if (!isAuthenticated) {
                const user = await authenticate({ signingMessage: "Connect to Digital Trading Cards Admin Panel" });
                // esto esta por las dudas, no si es necesario pero lo dejo qsy
                if (!user) {
                    await disconnect_wallet();
                    return
                };
                // si ya tiene rol aca corta esta funcion
                if (user.attributes.roleHash && user.attributes.roleName) return;
                // sino tiene
                const role = await get_role(user.attributes.ethAddress);
                // funcion que setea el rol en la db
                if (role.roleHash === 'norole') {
                    await Moralis.Cloud.run("SetNoRole", { address: user.attributes.ethAddress });
                } else {
                    await Moralis.Cloud.run("SetRole", {
                        address: user.attributes.ethAddress,
                        roleName: role.roleName,
                        roleHash: role.roleHash,
                    });
                }
            }
        } catch (error) {
            console.error(error);
            throw new Error('Error signing up: ', error);
        }
    };
    

    // disconnect wallet from Admin panel
    const disconnect_wallet = async () => {
        await logout()
            .then(() => null)
            .catch((error) => { throw new Error('Error loging out: ', error) })
    };

    /* Si hay cambio de cuenta => se deslogea de Moralis */
    try {
        const { ethereum } = window;
        if (ethereum) {
            ethereum.on('accountsChanged', async () => {
                await disconnect_wallet();
            });
        }
    } catch (error) {
        console.error('Error al escuchar evento onChainChanged');
    };

    // Roles
    const ROLES_HASH = {
        owner_hash: "0x0000000000000000000000000000000000000000000000000000000000000000",
        admin_hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("NFT_ADMIN_ROLE")),
        artist_hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("ARTIST_ROLE")),
    }

    // funcion que se llama si el usuario que se loguea NO tiene rol ya seteado
    // pregunta al contrato y setea la data en Moralis
    const get_role = async (_address) => {
        // conecto al contrato
        const provider = new ethers.providers.JsonRpcProvider("https://polygon-rpc.com");
        const contract = new ethers.Contract(rolesAddress, rolesAbi, provider);

        if (await contract.hasRole(ROLES_HASH.owner_hash, _address)) return { roleName: 'owner', roleHash: ROLES_HASH.owner_hash }

        if (await contract.hasRole(ROLES_HASH.admin_hash, _address)) return { roleName: 'admin', roleHash: ROLES_HASH.admin_hash }

        if (await contract.hasRole(ROLES_HASH.artist_hash, _address)) return { roleName: 'artist', roleHash: ROLES_HASH.artist_hash }

        return { roleName: 'norole', roleHash: 'norole' }
    };

    return (
        <UserContext.Provider value={{
            connect_wallet,
            disconnect_wallet,
            isAuthenticated,
            ROLES_HASH,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = React.useContext(UserContext);
    return context;
};