import * as React from 'react';
import './Welcome.css';

const Welcome = () => {
  return (
    <div className="container-welcome bg-welcome">

    </div>
  )
}

export default Welcome