import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const GoBack = () => {
    const navigate = useNavigate();

    return (
        <Typography color="primary" onClick={() => navigate(-1)} sx={{ cursor: 'pointer', fontWeight: '700', fontSize: '20px', display: 'flex', alignItems: 'center', opacity: '0.4', maxWidth: '240px' }}><KeyboardArrowLeftIcon />Go Back</Typography>
    )
}

export default GoBack