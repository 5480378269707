export const COLLECTION_INITIAL_STATE = {
    name: "",
    symbol: "",
    description: "",
    category: "",
    type: "",
    logoImg: undefined,
    bannerImg: undefined,
    price: undefined,
    maxPerWallet: 0,
    reveal: true,
    revealImg: undefined,
    contractMetadata: undefined,
    royaltiesSwitch: true,
    royalties: [],
    validRoyalties: false,
    validAddress: false,
};

export const ACTION_TYPE = {
    CHANGE_INPUT: "CHANGE_INPUT",
    SET_IMAGE: "SET_IMAGE",
    CHANGE_SWITCH: "CHANGE_SWITCH",
    ADD_ROYALTY: "ADD_ROYALTY",
    CHANGE_ROYALTY: "CHANGE_ROYALTY",
    REMOVE_ROYALTY: "REMOVE_ROYALTY",
};

export const collectionReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE.CHANGE_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case ACTION_TYPE.SET_IMAGE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case ACTION_TYPE.CHANGE_SWITCH:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case ACTION_TYPE.ADD_ROYALTY:
            const oldValue = state.royalties;
            return {
                ...state,
                royalties: [...oldValue, action.payload.value],
            }
        case ACTION_TYPE.CHANGE_ROYALTY:
            const newValue = state.royalties;
            if (action.payload.name === "beneficiary") newValue[action.payload.index]["beneficiary"] = action.payload.value;
            if (action.payload.name === "shares") newValue[action.payload.index]["shares"] = action.payload.value;
            return {
                ...state,
                royalties: newValue,
            }
        case ACTION_TYPE.REMOVE_ROYALTY:
            const newValues = state.royalties;
            newValues.splice(action.payload.value, 1);
            return {
                ...state,
                royalties: newValues,
            }
        default:
            return {
                ...state
            }
    }
}