import * as React from 'react';
// mis componentes
import BootstrapInput from '../../components/Inputs/BootstrapInput';
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
// layout
import { Row, Col } from 'react-bootstrap';
import { Typography, Button } from '@mui/material';
// hooks
import { useCMS } from '../../context/CMSContext';
import useUploading from "../../hooks/useUploading";
import { categoryReducer } from './categoryReducer';

const EditCategory = ({ _category }) => {
    const { cmsClient } = useCMS();

    const INITIAL_STATE = {
        name: _category.name,
        description: _category.description,
        logoImg: undefined,
        darkIcon: undefined,
        lightIcon: undefined,
        bannerImg: undefined,
    };

    // formulario reducer
    const [state, dispatch] = React.useReducer(categoryReducer, INITIAL_STATE);
    const handleInputChange = e => {
        dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value },
        })
    };
    const handleImgChange = e => {
        if (e.target.files && e.target.files.length > 0) {
            dispatch({
                type: "SET_IMAGE",
                payload: { name: e.target.name, value: e.target.files[0] },
            })
        }
    };

    // custom hooks y reducers juntos
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);

    // funcion para actualizar info
    const handleSubmit = async (e) => {
        e.preventDefault();
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        uploadToCMS()
    };
    const uploadToCMS = async () => {
        // subo las imagenes
        let coverImage = undefined;
        let banner = undefined;
        let darkIcon = undefined;
        let lightIcon = undefined;
        try {
            if (state.logoImg) coverImage = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.logoImg,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-logo.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} logo`,
                        title: `${state.name} logo`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });

            if (state.banerImg) banner = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.bannerImg,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-banner.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} banner`,
                        title: `${state.name} banner`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });

            if (state.darkIcon) darkIcon = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.darkIcon,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-darkIcon.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} dark icon`,
                        title: `${state.name} dark icon`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });

            if (state.lightIcon) lightIcon = await cmsClient.uploads.createFromFileOrBlob({
                // File object to upload
                fileOrBlob: state.lightIcon,
                // if you want, you can specify a different base name for the uploaded file
                filename: `${state.name}-lightIcon.jpeg`,
                // skip the upload and return an existing resource if it's already present in the Media Area:
                skipCreationIfAlreadyExists: true,
                // specify some additional metadata to the upload resource
                author: 'DTC',
                copyright: 'New copyright',
                default_field_metadata: {
                    en: {
                        alt: `${state.name} light icon`,
                        title: `${state.name} light icon`,
                        focal_point: {
                            x: 0.3,
                            y: 0.6,
                        },
                        custom_data: {
                            watermark: true,
                        },
                    },
                },
            });

            // se crea una nueva categoria en CMS
            const categoryUpdated = await cmsClient.items.update(_category.id, {
                name: state.name,
                description: state.description,
                ...(coverImage) && { cover_image: { upload_id: coverImage.id } },
                ...(lightIcon) && { light_icon: { upload_id: lightIcon.id } },
                ...(darkIcon) && { dark_icon: { upload_id: darkIcon.id } },
                ...(banner) && { banner_image: { upload_id: banner.id } },
            });
            console.log(categoryUpdated)
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
        } catch (error) {
            console.error(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };

    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Updating category"} _subtitle={`The category ${state.name} is being updated. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Category updated"} _subtitle={`The category ${state.name} has been successfully updated!`} _redirect={`/panel/categories`} />
            {/* Name */}
            <Row className="mb-4">
                <Typography variant="h6" component="h3" color="primary" className="my-2">Name: </Typography>
                <BootstrapInput name="name" defaultValue={state.name} className="p-0" placeholder="Category name:" type="text" onChange={handleInputChange} />
            </Row>
            {/* Description */}
            <Row className="my-4">
                <Typography variant="h6" component="h3" color="primary" className="my-2">Description: </Typography>
                <BootstrapInput name="description" defaultValue={state.description} placeholder="Category description:" type="text" multiline rows="4" onChange={handleInputChange} />
            </Row>
            {/* Cover image */}
            <Row className="mt-3 mb-4">
                <Typography variant="h6" component="h3" color="primary" className="my-2">Cover image: </Typography>
                <Col className="px-0">
                    <label style={{ 'border': state.logoImg ? 'none' : '', 'justifyContent': state.logoImg ? 'flex-start' : 'center' }}
                        htmlFor="logoImg" className="custom-upload" >
                        {!state.logoImg
                            ? <img src={_category.coverImage.url} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />
                            : <img src={URL.createObjectURL(state.logoImg)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="logoImg" id="logoImg" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            {/* Icons */}
            <Row className="mt-3 mb-4">
                <Col className="px-0">
                    <Typography variant="h6" component="h3" color="primary" className="my-2">Light icon: </Typography>
                    <label style={{ 'border': state.lightIcon ? 'none' : '', 'justifyContent': state.lightIcon ? 'flex-start' : 'center' }}
                        htmlFor="lightIcon" className="custom-upload" >
                        {!state.lightIcon
                            ? <img src={_category.lightIcon.url} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />

                            : <img src={URL.createObjectURL(state.lightIcon)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="lightIcon" id="lightIcon" onChange={handleImgChange} />
                    </label>
                </Col>
                <Col className="px-0">
                    <Typography variant="h6" component="h3" color="primary" className="my-2">Dark icon: </Typography>
                    <label style={{ 'border': state.darkIcon ? 'none' : '', 'justifyContent': state.darkIcon ? 'flex-start' : 'center' }}
                        htmlFor="darkIcon" className="custom-upload" >
                        {!state.darkIcon
                            ? <img src={_category.darkIcon.url} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />
                            : <img src={URL.createObjectURL(state.darkIcon)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="darkIcon" id="darkIcon" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            {/* Banner */}
            <Row className="my-4">
                <Typography variant="h6" component="h3" color="primary" className="my-2">Banner image: </Typography>
                <Col className="px-0">
                    <label style={{ 'border': state.bannerImg ? 'none' : '', 'justifyContent': state.bannerImg ? 'flex-start' : 'center' }} htmlFor="bannerImg" className="custom-banner-upload">
                        {!state.bannerImg
                            ? <img src={_category.bannerImage.url} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '220px', borderRadius: '13px' }} />
                            : <img src={URL.createObjectURL(state.bannerImg)} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '220px', borderRadius: '13px' }} />}
                        <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="bannerImg" id="bannerImg" onChange={handleImgChange} />
                    </label>
                </Col>
            </Row>
            <Row className="my-4">
                <Col md={8} lg={4} className="ps-0">
                    <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit} sx={{ fontSize: '1.25rem' }}
                        disabled={((state.name === INITIAL_STATE.name) && (state.description === INITIAL_STATE.description) && !state.logoImg && !state.darkIcon && !state.lightIcon && !state.bannerImg)}>
                        Update Category
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default EditCategory