import * as React from 'react';
// mis componentes
import BootstrapInput from '../../components/Inputs/BootstrapInput';
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
// layout
import { Row, Col, Container } from 'react-bootstrap';
import { Typography, Button } from '@mui/material';
// hooks
import { useCMS } from '../../context/CMSContext';
import useUploading from "../../hooks/useUploading";
import { collectionCMSReducer } from './collectionCMSReducer';

const DisplayInfo = ({ _collection }) => {

  const { cmsClient } = useCMS();

  const INITIAL_STATE = {
    name: _collection.name,
    description: _collection.description,
    logoImg: undefined,
    bannerImg: undefined,
  };
  // formulario reducer
  const [state, dispatch] = React.useReducer(collectionCMSReducer, INITIAL_STATE);
  const handleInputChange = e => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    })
  };
  const handleImgChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch({
        type: "SET_IMAGE",
        payload: { name: e.target.name, value: e.target.files[0] },
      })
    }
  };

  // custom hooks y reducers juntos
  const wait = useUploading();
  const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);

  // funcion para actualizar info
  const handleSubmit = async (e) => {
    e.preventDefault();
    waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
    uploadToCMS()
  };
  const uploadToCMS = async () => {
    // subo las imagenes
    let coverImage = undefined;
    let banner = undefined;
    try {
      if (state.logoImg) coverImage = await cmsClient.uploads.createFromFileOrBlob({
        // File object to upload
        fileOrBlob: state.logoImg,
        // if you want, you can specify a different base name for the uploaded file
        filename: `${state.name}-logo.jpeg`,
        // skip the upload and return an existing resource if it's already present in the Media Area:
        skipCreationIfAlreadyExists: true,
        // specify some additional metadata to the upload resource
        author: 'DTC',
        copyright: 'New copyright',
        default_field_metadata: {
          en: {
            alt: `${state.name} logo`,
            title: `${state.name} logo`,
            focal_point: {
              x: 0.3,
              y: 0.6,
            },
            custom_data: {
              watermark: true,
            },
          },
        },
      });

      if (state.banerImg) banner = await cmsClient.uploads.createFromFileOrBlob({
        // File object to upload
        fileOrBlob: state.bannerImg,
        // if you want, you can specify a different base name for the uploaded file
        filename: `${state.name}-banner.jpeg`,
        // skip the upload and return an existing resource if it's already present in the Media Area:
        skipCreationIfAlreadyExists: true,
        // specify some additional metadata to the upload resource
        author: 'DTC',
        copyright: 'New copyright',
        default_field_metadata: {
          en: {
            alt: `${state.name} banner`,
            title: `${state.name} banner`,
            focal_point: {
              x: 0.3,
              y: 0.6,
            },
            custom_data: {
              watermark: true,
            },
          },
        },
      });

      // se actualiza la coleccion en CMS
      const collectionUpdated = await cmsClient.items.update(_collection.id, {
        name: state.name,
        description: state.description,
        ...(coverImage) && { cover_image: { upload_id: coverImage.id } },
        ...(banner) && { banner_image: { upload_id: banner.id } },
      });
      console.log(collectionUpdated);

      const item = await cmsClient.items.publish(_collection.id, {
        recursive: 'true'
      });
      console.log(item);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
    } catch (error) {
      console.error(error);
      waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
    }
  };

  return (
    <Container>
      <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Updating collection"} _subtitle={`The collection ${state.name} is being updated. Please wait.`} />
      <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Collection updated"} _subtitle={`The collection ${state.name} has been successfully updated!`} _redirect={`/panel/category/${_collection.category.slug}`} />
      {/* Name */}
      <Row className="mb-4">
        <Typography variant="h6" component="h3" color="primary" className="my-2">Name: </Typography>
        <BootstrapInput name="name" defaultValue={state.name} className="p-0" placeholder="Category name:" type="text" onChange={handleInputChange} />
      </Row>
      {/* Description */}
      <Row className="my-4">
        <Typography variant="h6" component="h3" color="primary" className="my-2">Description: </Typography>
        <BootstrapInput name="description" defaultValue={state.description} placeholder="Category description:" type="text" multiline rows="4" onChange={handleInputChange} />
      </Row>
      {/* Cover image */}
      <Row className="mt-3 mb-4">
        <Typography variant="h6" component="h3" color="primary" className="my-2">Cover image: </Typography>
        <Col className="px-0">
          <label style={{ 'border': state.logoImg ? 'none' : '', 'justifyContent': state.logoImg ? 'flex-start' : 'center' }}
            htmlFor="logoImg" className="custom-upload" >
            {!state.logoImg
              ? <img src={_collection.coverImage.url} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />
              : <img src={URL.createObjectURL(state.logoImg)} width="auto" height="220px" alt="imagen" style={{ margin: "1rem 0", maxWidth: '340px', borderRadius: '5%' }} />}
            <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="logoImg" id="logoImg" onChange={handleImgChange} />
          </label>
        </Col>
      </Row>
      {/* Banner */}
      <Row className="my-4">
        <Typography variant="h6" component="h3" color="primary" className="my-2">Banner image: </Typography>
        <Col className="px-0">
          <label style={{ 'border': state.bannerImg ? 'none' : '', 'justifyContent': state.bannerImg ? 'flex-start' : 'center' }} htmlFor="bannerImg" className="custom-banner-upload">
            {!state.bannerImg
              ? <img src={_collection.bannerImage.url} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '220px', borderRadius: '13px' }} />
              : <img src={URL.createObjectURL(state.bannerImg)} width="auto" height="auto" alt="imagen" style={{ margin: "1rem 0", maxWidth: '100%', maxHeight: '220px', borderRadius: '13px' }} />}
            <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif" name="bannerImg" id="bannerImg" onChange={handleImgChange} />
          </label>
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={8} lg={4} className="ps-0">
          <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit} sx={{ fontSize: '1.25rem' }}
            disabled={((state.name === INITIAL_STATE.name) && (state.description === INITIAL_STATE.description) && !state.logoImg && !state.bannerImg)}>
            Update Collection
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default DisplayInfo