/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, Typography, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDTCs } from '../../context/DTCsContext';
import GoBack from '../../components/GoBack/GoBack';
import MintButton from '../../components/Buttons/MintButton';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';

const DTCsDetail = () => {
    const { address, slug } = useParams();
    const { get_dtc, put_on_sale, addToFavorites, removeFavorites, changeOrder, get_dtcs_length, isInFavorites } = useDTCs();
    const [thisDTC, setThisDTC] = React.useState();
    const [isInFavs, setIsInFavs] = React.useState();
    const [dtcsLength, setDTCsLength] = React.useState([]);
    const [newOrder, setNewOrder] = React.useState();

    // modales de confirmacion
    const [successInfo, setSuccessInfo] = React.useState({ success: false });

    React.useEffect(() => {
        if (isNaN(slug)) {
            get_dtc({ _moralisId: slug, _addCategory: true, _addCollection: true }).then((r) => {
                setThisDTC(r)
                setNewOrder(r.attributes.order)
            })
            return
        }
        get_dtc({ _collectionAddress: address, _tokenId: slug, _addCategory: true, _addCollection: true }).then((r) => {
            setThisDTC(r)
            setNewOrder(r.attributes.order)
        });
    }, [slug])

    React.useEffect(() => {
        if (!thisDTC) return

        isInFavorites(thisDTC?.id)
            .then(response => setIsInFavs(response))

        get_dtcs_length({ _onlyLength: true })
            .then((response) => {
                setDTCsLength(Array.from({ length: response }, (_, i) => i + 1));
            })
            .catch(err => console.error(err))

    }, [thisDTC])

    const on_sale = async () => {
        await put_on_sale({ _moralisId: slug })
        window.location.reload();
    };

    const change_order = async (_thisDTC, _newOrder) => {
        const response = await changeOrder(_thisDTC, _newOrder);
        if (response) setSuccessInfo({ success: response, title: "DTC order changed", subtitle: `DTC ${thisDTC?.attributes.name} order changed.` })
    };

    const remove_favorites = async (_id) => {
        const response = await removeFavorites(_id);
        if (response) setSuccessInfo({ success: response, title: "Favorite removed", subtitle: `DTC ${thisDTC?.attributes.name} removed from favorites.` })
    };

    const add_favorite = async (_id) => {
        const response = await addToFavorites(_id);
        if (response) setSuccessInfo({ success: response, title: "Favorite added", subtitle: `DTC ${thisDTC?.attributes.name} added to favorites.` })
    }

    if (!thisDTC) return

    return (
        <>
            <ConfirmationModal _open={successInfo.success} _handleClose={() => null} _title={successInfo.title} _subtitle={successInfo.subtitle} _redirect={`/panel/admin/gallery`} />
            <Container className="my-5">
                <Row>
                    {/* FOTO */}
                    <Col xl={6} lg={12} className="d-flex justify-content-center">
                        <VideoPlayer _img={thisDTC?.attributes?.image} _controls={false} />
                    </Col>
                    {/* INFO */}
                    <Col xl={6} lg={12}>
                        <Row><GoBack /></Row>
                        {/* nombre */}
                        <Typography variant="h2" color="primary" fontWeight={'500'}>{thisDTC?.attributes.name}</Typography>
                        {/* coleccion */}
                        <Typography variant="subtitle2" color="black" className="mt-3">{(thisDTC?.collection.category).toUpperCase()} / {(thisDTC?.collection.displayName).toUpperCase()}</Typography>
                        {/* descripcion */}
                        <Typography variant="subtitle2" color="darkgray" className="mt-3">{thisDTC?.attributes.description}</Typography>
                        {/* botones */}
                        {thisDTC?.attributes.state === 1
                            && <Row>
                                <Col className="w-100 d-flex justify-content-center align-items-center my-5">
                                    <label style={{ backgroundColor: '#ffffff', borderRadius: '4px', padding: '6px', color: 'var(--primary-main)', fontSize: '1.25rem' }}>State: DRAFT</label>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button variant="contained" color="primary" sx={{ fontSize: '1.25rem' }}
                                        onClick={on_sale}>
                                        Put on sale
                                    </Button>
                                </Col>
                            </Row>}
                        {thisDTC?.attributes.state === 2 && <MintButton _dtc={{ _collectionAddress: thisDTC?.attributes.collectionAddress, _json: thisDTC?.attributes.json, _tokenId: 40568668, _moralisId: slug }} />}
                        {thisDTC?.attributes.state === 3 && <MintButton _dtc={{ _collectionAddress: thisDTC?.attributes.collectionAddress, _json: thisDTC?.attributes.json, _tokenId: Number(slug) }} />}
                        {isInFavs
                            ? <Button onClick={() => remove_favorites(thisDTC.id)}>Remove from Favorites</Button>
                            : <Button onClick={() => add_favorite(thisDTC.id)}>Add to Favorites</Button>}
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-helper-label">Order</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={newOrder}
                                label="Orden"
                                onChange={(evt) => setNewOrder(evt.target.value)}
                            >
                                {
                                    dtcsLength?.map((val, index) =>
                                        <MenuItem value={Number(index) + 1} key={index}>{Number(index) + 1}</MenuItem>
                                    )
                                }
                            </Select>
                            <FormHelperText>Change DTC order</FormHelperText>
                        </FormControl>
                        <Button variant="contained" className="my-0 mx-4 p-3" disabled={Number(thisDTC.attributes.order) === Number(newOrder)} onClick={() => change_order(thisDTC, newOrder)}>Update DTC order</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DTCsDetail