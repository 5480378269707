import * as React from 'react';
import { Button, InputAdornment } from '@mui/material';
import { useDTCs } from '../../context/DTCsContext';
import { Row, Col } from 'react-bootstrap';
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import WaitingModal from '../Modals/WaitingModal/WaitingModal';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';
import useUploading from "../../hooks/useUploading";

const MintButton = ({ _dtc }) => {
    const { mint_dtc, put_as_sold } = useDTCs();
    const [amountToMint, setAmountToMint] = React.useState(0);
    // hook de espera
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);
    // funciones
    const handleSubmit = async () => {
        try {
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
            const response = await mint_dtc(_dtc, amountToMint);
            if (!response.success) throw new Error("Error")
            if (_dtc._tokenId !== 40568668 && response.success) {
                waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
                return
            }
            const moralisResponse = await put_as_sold({ _moralisId: _dtc._moralisId })
            if (moralisResponse.success) waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
            if (!moralisResponse.success) throw new Error("Error")
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    }

    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"DTC minting"} _subtitle={`A new DTC is being minted. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"DTC minted"} _subtitle={`A new DTC was minted.`} _redirect={`/panel/${_dtc._collectionAddress}`} />
            <Row className="w-100 d-flex justify-content-center my-5">
                <Col xxl={5}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}
                        sx={{ fontSize: '1.25rem' }} disabled={amountToMint === 0}>
                        {_dtc._tokenId === 40568668 ? 'Mint this DTC' : 'Add more DTCs'}
                    </Button>
                </Col>
                <Col xxl={5}>
                    <BootstrapInput type="number"
                        name="amountToMint" className="w-100"
                        endAdornment={<InputAdornment position="end">DTCs to mint</InputAdornment>}
                        onChange={(e) => setAmountToMint(Number(e.target.value))}
                    />
                </Col>
            </Row>
        </>
    )
}

export default MintButton