/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { Typography, Button } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import { useCollections } from '../../../context/CollectionsContext';
import { useDTCs } from '../../../context/DTCsContext';
import CardsDisplay from '../../../models/CardsDisplay/CardsDisplay';

const Collection = () => {
    let { address } = useParams();
    const { get_collection } = useCollections();
    const { get_dtcs, get_dtcs_length } = useDTCs();
    const [name, setName] = React.useState('Collection Name');
    const [DTCsIn, setDTCsIn] = React.useState([]);
    const [length, setLength] = React.useState();

    const get_all_data = async () => {
        if(!address) get_all_data();
        Promise.all([
            get_collection(address),
            get_dtcs_length({ _collectionAddress: address, _onlyLength: true }),
            get_dtcs({ _collectionAddress: address, _offset: (Number(page - 1) * limit), _limit: limit })
        ])
            .then((response) => {
                
                setName(response[0].attributes.displayName);
                setLength(response[1]);
                setDTCsIn(response[2]);
            })
    };
    React.useEffect(() => {
        get_all_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    // paginacion
    const [page, setPage] = React.useState(1);
    const handlePaginationChange = (e, value) => {
        setPage(value)
    };
    const limit = 8;

    React.useEffect(() => {
        if (name !== 'Collection Name') get_dtcs({ _collectionAddress: address, _offset: (Number(page - 1) * limit), _limit: limit })
            .then((response) => setDTCsIn(response))
    }, [page, limit])

    return (
        <Container>
            <GoBack />
            <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1 mx-2 mb-5">{name}</Typography>
            <CardsDisplay _cards={DTCsIn} _length={length} _type={'dtcs'}
                _page={page} _limit={limit}  _handlePaginationChange={handlePaginationChange} />
        </Container>
    )
}

export default Collection