import * as React from 'react'
import { useMoralis } from 'react-moralis';
import { useUser } from '../../context/UserContext';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import NoRoleModal from '../../components/Modals/NoRoleModal/NoRoleModal';
import Loading from '../../components/Loading/Loading';

const RequireArtistRole = () => {
    const { isAuthenticated, user } = useMoralis();
    const { ROLES_HASH } = useUser()
    const location = useLocation();
    const [loading, setLoading] = React.useState(true);

    setTimeout(() => { setLoading(false) }, [250])

    if (loading) return <Loading />

    // si es owner o admin
    if (isAuthenticated &&
        ((user?.attributes.roleName === 'artist' && user?.attributes.roleHash === ROLES_HASH.artist_hash) || (user?.attributes.roleName === 'owner' && user?.attributes.roleHash === ROLES_HASH.owner_hash)))
        return <Outlet />

    // si no esta logueado te redirige al login
    if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />

    // si no tiene rol
    if (isAuthenticated && user?.attributes.roleHash === 'norole') return <NoRoleModal _open={true} />


    return <Navigate to="/" state={{ from: location }} replace />
}

export default RequireArtistRole