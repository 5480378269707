import * as React from "react";
import { ethers } from "ethers";
import axios from "axios";

const networks = [
    {
        key: "0x1",
        chainId: 1,
        value: "Ethereum Mainnet",
        rpcurl: "https://speedy-nodes-nyc.moralis.io/c58c261f5ea279890bb727ac/eth/mainnet",
        currencySymbol: "ETH",
    },
    {
        key: "0x4",
        chainId: 4,
        value: "Rinkeby Testnet",
        rpcurl: "https://speedy-nodes-nyc.moralis.io/c58c261f5ea279890bb727ac/eth/rinkeby",
        currencySymbol: "ETH",
    },
    {
        key: "0x89",
        chainId: 137,
        value: "Polygon Mainnet",
        rpcurl: "https://speedy-nodes-nyc.moralis.io/c58c261f5ea279890bb727ac/polygon/mainnet",
        currencySymbol: "MATIC",
    },
    {
        key: "0x13881",
        chainId: 80001,
        value: "Polygon Mumbai Testnet",
        rpcurl: "https://speedy-nodes-nyc.moralis.io/c58c261f5ea279890bb727ac/polygon/mumbai",
        currencySymbol: "MATIC",
    },
    {
        key: "0x38",
        chainId: 56,
        value: "Binance Smart Chain",
        rpcurl: "https://bsc-dataseed.binance.org/",
        currencySymbol: "BNB",
    },
    {
        key: "0x61",
        value: "BSC Testnet",
        chainId: 97,
        rpcurl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        currencySymbol: "BNB",
    },
    {
        key: "0xA86A",
        chainId: 43114,
        value: "Avalanche Mainnet",
        rpcurl: "https://api.avax.network/ext/bc/C/rpc",
        currencySymbol: "AVAX",
    },
    {
        key: "0xA869",
        value: "Avalanche Testnet",
        chainId: 43113,
        rpcurl: "https://api.avax-test.network/ext/bc/C/rpc",
        currencySymbol: "AVAX",
    },
    {
        key: "0x63564C40",
        value: "Harmony Mainnet",
        chainId: 1666600000,
        rpcurl: "https://harmony-0-rpc.gateway.pokt.network",
        currencySymbol: "ONE",
    },
    {
        key: "0xFA",
        chainId: 250,
        value: "Fantom Mainnet",
        rpcurl: "https://rpc.ftm.tools/",
        currencySymbol: "FTM",
    },
];

const networkInUse = {
    key: "0x89",
    value: "Polygon Mainnet",
    rpcurl: "https://speedy-nodes-nyc.moralis.io/c58c261f5ea279890bb727ac/polygon/mainnet",
    currencySymbol: "MATIC",
};

const NetworkContext = React.createContext([]);

NetworkContext.displayName = "NetworkContext";

export const NetworkProvider = ({ children }) => {

    const onlyReadProvider = new ethers.providers.JsonRpcProvider("https://polygon-rpc.com");
    // red actual
    const [currentNetwork, setCurrentNetwork] = React.useState("");
    // red equivocada
    const [wrongNetwork, setWrongNetwork] = React.useState(false);

    const getNetwork = async () => {
        try {
            const { ethereum } = window;
            if (!ethereum) throw new Error("No Metamask")
            const provider = new ethers.providers.Web3Provider(ethereum);
            const network = await provider.getNetwork();
            const newNetwork = networks.filter(menuItem => menuItem.chainId === network.chainId);
            setCurrentNetwork(newNetwork[0].value);
        } catch (error) {
            console.error(error);
        }
    };
    const switchNetwork = async () => {
        try {
            await window.ethereum.request({
                "jsonrpc": "2.0",
                "method": "wallet_switchEthereumChain",
                "params": [
                    {
                        "chainId": networkInUse.key,
                    }
                ]

            })
        } catch (e) {
            if (e.code === 4902) {
                await window.ethereum.request({
                    "jsonrpc": "2.0",
                    "method": "wallet_addEthereumChain",
                    "params": [
                        {
                            "chainName": networkInUse.value,
                            "chainId": networkInUse.key,
                            "rpcUrls": [networkInUse.rpcurl],
                            "nativeCurrency": {
                                "name": "string",
                                "symbol": networkInUse.currencySymbol,
                                "decimals": 18,
                            },
                        }
                    ]

                })
            }
        }
        setCurrentNetwork(networkInUse.value)
        await getNetwork();
        window.location.reload();
    };
    const getMaxGas = async () => {
        let feeData = await axios.get('https://gasstation-mainnet.matic.network/');
        let fastestGas = ethers.utils.parseUnits((feeData.data.fastest).toString(), "gwei");
        return fastestGas
    };

    // cada vez que cambia la red actual chequea que sea la correcta
    React.useEffect(() => {
        if (currentNetwork === "" || currentNetwork === "Polygon Mainnet") {
            setWrongNetwork(false)
        } else {
            setWrongNetwork(true)
        }
    }, [currentNetwork])

    /* Si hay cambio de red => reload */
    try {
        const { ethereum } = window;
        if (ethereum) {
            ethereum.on('chainChanged', (chainId) => {
                window.location.reload();
            });
        }
    } catch (error) {
        console.error('Error al escuchar evento onChainChanged');
    }

    return (
        <NetworkContext.Provider value={{
            onlyReadProvider,
            currentNetwork,
            wrongNetwork,
            getNetwork,
            switchNetwork,
            getMaxGas,
        }}>
            {children}
        </NetworkContext.Provider>
    );
};

export const useNetwork = () => {
    const context = React.useContext(NetworkContext);
    return context;
};