import * as React from 'react';
import { ethers } from "ethers";
import { useNetwork } from '../../context/NetworkContext';
// layout
import { Col } from 'react-bootstrap';
// mui
import { Button } from '@mui/material';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import WaitingModal from '../Modals/WaitingModal/WaitingModal';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';
import useUploading from "../../hooks/useUploading";

const PauseContract = ({ _address, _abi }) => {
    // hook de espera
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);
    // imports del context
    const { onlyReadProvider, getMaxGas } = useNetwork();
    // variables
    const [isPaused, setIsPaused] = React.useState(false)
    // Funcion de lectura
    const get_paused = async () => {
        try {
            // Conecto la wallet
            let contract = new ethers.Contract(_address, _abi, onlyReadProvider);
            // Obtenemos el precio del NFT en USD
            await contract.paused()
                .then((r) => setIsPaused(r))
                .catch((e) => console.log(e))
        } catch (error) {
            throw new Error("Error getting the price");
        }
    };
    React.useEffect(() => {
        get_paused();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Funciones que pausan y despausan
    const unpause = async () => {
        try {
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
            const { ethereum } = window;
            if (!ethereum) throw new Error("No Metamask");
            // Conecto la wallet
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            let contract = new ethers.Contract(
                _address,
                _abi,
                signer
            );
            let fastestGas = await getMaxGas();
            const tx = await contract.unpause({ gasPrice: fastestGas })
            await tx
                .wait()
                .then(() => waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS }))
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };
    const pause = async () => {
        try {
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
            const { ethereum } = window;
            if (!ethereum) throw new Error("No Metamask");
            // Conecto la wallet
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            let contract = new ethers.Contract(
                _address,
                _abi,
                signer
            );
            let fastestGas = await getMaxGas();
            const tx = await contract.pause({ gasPrice: fastestGas })
            await tx
                .wait()
                .then(() => waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS }))
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };
    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Updating roles contract"} _subtitle={`The roles contract is being ${isPaused ? 'unpaused' : 'paused'}. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Roles contract updated"} _subtitle={`The roles contract has been ${isPaused ? 'unpaused' : 'paused'}`} _redirect={`/panel/admin/roles`} />
            <Col className='d-flex align-items-center justify-content-end'>
                <label style={{ backgroundColor: '#ffffff', borderRadius: '4px', padding: '6px', color: 'var(--primary-main)' }}>{isPaused ? 'Status: Paused' : 'Status: Active'}</label>
                <Button variant="contained" color="primary" className="m-2" startIcon={isPaused ? <PlayCircleOutlineOutlinedIcon /> : <PauseCircleOutlineOutlinedIcon />} onClick={isPaused ? unpause : pause}>{isPaused ? 'Unpause' : 'Pause'} roles contract</Button>
            </Col>
        </>
    )
}

export default PauseContract