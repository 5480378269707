import * as React from 'react'
// Componentes MUI
import { Typography, Grid, Tooltip, IconButton } from '@mui/material';
// Iconos MUI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelperIcon from '../HelperIcon/HelperIcon';

const ContractAddress = ({ _addr, _name, _helpInfo, _helper = true }) => {
    return (
        <Grid className='d-flex align-items-center flex-row mt-3'>
            {_helper ? <HelperIcon _help={_helpInfo} /> : null}
            <Typography variant="subtitle2" color='secondary' sx={{ fontSize: '1.05rem' }}>
                {_name ? _name : null} contract address:
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Typography variant="subtitle2" sx={{ color: 'var(--gris-claro)', fontSize: '1.05rem' }}>{(_addr?.toLowerCase()).slice(0, 5)}...{(_addr?.toLowerCase()).slice(37)} <Tooltip title="Copy address" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(_addr)}><ContentCopyIcon /></IconButton></Tooltip></Typography>
        </Grid>
    )
}

export default ContractAddress