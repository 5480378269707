import * as React from 'react';
import ModalCard from "../ModalCard";
import { useMoralis } from "react-moralis";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';
import { Typography, Button, Divider } from "@mui/material";

const NoRoleModal = ({ _open, _norole = true }) => {
    const navigate = useNavigate();
    const { user } = useMoralis();
    const { disconnect_wallet } = useUser();

    const routeChange = async () => {
        // si no tiene rol
        if (_norole) {
            await disconnect_wallet();
            navigate("/login");
            return
        };
        navigate("/");
    };

    return (
        <ModalCard className="confirmation-modal-properties modal-properties" open={_open} handleClose={null}>
            <Typography type="blockquoteRegular">
                Access denied
            </Typography>

            <Divider className={"divider mt-3"} />

            <div className="modal-container">
                <Typography className={"modal-text"}>
                    The address {user.attributes.ethAddress} does not have the role to access this {_norole ? 'admin panel' : 'section'}.
                </Typography>
            </div>

            <Divider className={"divider"} />


            <div className={"button-section mt-2"}>
                <Button variant="contained" color="info" onClick={routeChange}>
                    Continue
                </Button>
            </div>
        </ModalCard>
    )
}

export default NoRoleModal