/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useDTCs } from '../../context/DTCsContext';
import CardsDisplay from './CardsDisplay';
import Loading from '../../components/Loading/Loading';
import { Dropdown, Container } from 'react-bootstrap';
import './gallery.css'

const DTCsGallery = ({ _states, _collection, _creator }) => {

    const { get_dtcs, get_favorites } = useDTCs();
    const [initialLoading, setInitialLoading] = React.useState(true);
    // Array donde van a ir los DTCs
    const [dtcs, setDTCs] = React.useState([]);
    const [onlyFavorites, setOnlyFavorites] = React.useState(false);

    const [page, setPage] = React.useState(1);
    const limit = 7;
    const [paginationLength, setPaginationLength] = React.useState(1);

    // paginacion
    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    // Cada vez que cambie algun parametro, se llama a la funcion get_dtcs nuevamente
    React.useEffect(() => {
        setInitialLoading(true);
        if (onlyFavorites) {
            get_favorites(_states)
                .then(response => {
                    setPaginationLength(response.length);
                    setDTCs(response)
                })
                .then(() => setInitialLoading(false))
                .catch(err => console.error(err))
        } else {
            get_dtcs({ _collection: _collection, _state: _states, _creator: _creator, _offset: ((page - 1) * limit), _limit: limit })
                .then((response) => setDTCs(response))
                .then(() => setInitialLoading(false))
                .catch(err => console.error(err))
        }
    }, [page, limit, onlyFavorites])

    // Cada vez que cambie algun parametro, se llama a la funcion get_dtcs nuevamente
    React.useEffect(() => {
        setInitialLoading(true);
        get_dtcs({ _collection: _collection, _state: _states, _creator: _creator, _onlyLength: true })
            .then((response) => setPaginationLength(response))
    }, [])

    if (initialLoading) return <Loading />

    return (
        <>
            <Container fluid className="f-flex justify-content-end" id="dropdown-favs-container">
                <Dropdown id="dropdown-favs">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setOnlyFavorites(false)}>All DTCs</Dropdown.Item>
                        <Dropdown.Item onClick={() => setOnlyFavorites(true)}>Favorites</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
            <CardsDisplay _cards={dtcs} onlyFavorites={onlyFavorites} _length={paginationLength} _type={'dtcs'} _page={page} _handlePaginationChange={handlePaginationChange} _limit={limit} />
        </>
    )
}

export default DTCsGallery