import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Pagination } from '@mui/material';
// cards
import DTCsCard from '../../components/Cards/DTCsCard';
import AddDTCsCards from '../../components/Cards/AddDTCsCards';
import CollectionsCard from '../../components/Cards/CollectionsCard';
import AddCollectionCard from '../../components/Cards/AddCollectionCard';

const CardsDisplay = ({ _cards, _length, _type = 'collections', onlyFavorites, _page, _status, _handlePaginationChange, _limit = 8 }) => {

    return (
        <>
            <Row className="my-2 w-100">
                {(_cards?.length > 0) && !onlyFavorites && _cards?.map((_item, index) =>
                    <Col key={index} xxl={_type === 'dtcs' ? 3 : 4} xl={6} className="d-flex justify-content-center">
                        {(_type === 'dtcs') && <DTCsCard _name={_item.attributes.name} _img={_item.attributes.image} _itemId={_item.id} _link={`/panel/${_item.attributes.collectionAddress}/${(_item.attributes.tokenId || _item.attributes.tokenId === 0) ? _item.attributes.tokenId : _item.id}`} _state={_item.attributes.state} />}
                        {_type === 'collections' && <CollectionsCard _name={_item.name} _img={_item.coverImage} _collection={true} _slug={_item.contractAddress} />}
                        {_type === 'categories' && <CollectionsCard _name={_item.name} _img={_item.coverImage} _collection={false} _slug={_item.slug} />}
                    </Col>
                )}
                {onlyFavorites && _cards.length > 0 && _cards?.map((_item, index) =>
                    <Col key={index} xxl={_type === 'dtcs' ? 3 : 4} xl={6} className="d-flex justify-content-center">
                        {(_type === 'dtcs') && <DTCsCard _name={_item.attributes.name} _img={_item.attributes.image} _itemId={_item.id} _link={`/panel/${_item.attributes.collectionAddress}/${(_item.attributes.tokenId || _item.attributes.tokenId === 0) ? _item.attributes.tokenId : _item.id}`} _state={_item.attributes.state} />}
                        {_type === 'collections' && <CollectionsCard _name={_item.name} _img={_item.coverImage} _collection={true} _slug={_item.contractAddress} />}
                        {_type === 'categories' && <CollectionsCard _name={_item.name} _img={_item.coverImage} _collection={false} _slug={_item.slug} />}
                    </Col>
                )}
                <Col xxl={_type === 'dtcs' ? 3 : 4} xl={6} className="d-flex justify-content-center">
                    {_type === 'dtcs' && <AddDTCsCards />}
                    {_type === 'collections' && <AddCollectionCard />}
                    {_type === 'categories' && <AddCollectionCard _category={true} />}
                </Col>
            </Row>
            {(_limit > 0 && !isNaN(_page) && !isNaN(_length))
                ? <Row className='my-3'>
                    <Pagination count={Math.max(Math.ceil(_length / _limit), 1)} page={_page} onChange={_handlePaginationChange}
                        color="primary" size='large' />
                </Row>
                : null}
        </>
    )
}

export default CardsDisplay