import * as React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Typography } from '@mui/material';
// componentes
import GoBack from "../../../components/GoBack/GoBack";
import ContractAddress from '../../../components/ContractAddress/ContractAddress';
// contratos
import { rolesAddress } from '../../../utils/rolesContract';
import { factoryAddress } from '../../../utils/factoryContract';
import { lazyMintAddress } from '../../../utils/lazyMintContract';

const ContractsAddresses = () => {
    return (
        <Container>
            <GoBack />
            <Row className="d-flex align-items-center">
                <Col>
                    <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1">
                        All contract addresses
                    </Typography>
                </Col>
            </Row>
            <Row className="mb-1 mt-2">
                <ContractAddress _addr={rolesAddress} _name={'Roles'} />
            </Row>
            <Row className="mb-1">
                <ContractAddress _addr={factoryAddress} _name={'Factory'} />
            </Row>
            <Row className="mb-1">
                <ContractAddress _addr={lazyMintAddress} _name={'Lazy Mint'} />
            </Row>
        </Container>
    )
}

export default ContractsAddresses