export const INITIAL_STATE = {
    name: "",
    description: "",
    logoImg: undefined,
    darkIcon: undefined,
    lightIcon: undefined,
    bannerImg: undefined,
};

export const categoryReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case "SET_IMAGE":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        default:
            return {}
    }
}