import * as React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useMoralis } from 'react-moralis';
import NoRoleModal from '../../components/Modals/NoRoleModal/NoRoleModal';
import Loading from '../../components/Loading/Loading';

const RequireConnected = () => {
    const { isAuthenticated, user } = useMoralis();
    const location = useLocation();
    const [loading, setLoading] = React.useState(true);

    setTimeout(() => { setLoading(false) }, [250])

    if (loading) return <Loading />

    // si no esta logueado te redirige al login
    if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />
    
    // si no tiene rol
    if (isAuthenticated && user?.attributes.roleHash === 'norole') return <NoRoleModal _open={true} />

    return <Outlet />
}

export default RequireConnected