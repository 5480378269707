// export const factoryAddress = "0xA71fFAb221F2e2616d34aeE8b8F48ea653E37018";     // QA
export const factoryAddress = "0x4b1f4Ae2c4b5E44601A97897fDa0570f7Cd8FCE3";     // Produccion
export const factoryAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_rolesContract",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_implementation1155",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_implementation721",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_splitterImplementation",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "collectionAddress",
                "type": "address"
            }
        ],
        "name": "CollectionDeployed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "collectionAddress",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address[]",
                "name": "_payees",
                "type": "address[]"
            },
            {
                "indexed": false,
                "internalType": "uint256[]",
                "name": "_shares",
                "type": "uint256[]"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "name",
                "type": "string"
            }
        ],
        "name": "SplitterDeployed",
        "type": "event"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_name",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_rolesContract",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "_contractUri",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_lazyMintingPrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "_revealed",
                        "type": "bool"
                    },
                    {
                        "internalType": "string",
                        "name": "_revealUrl",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_royaltyFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "_splitterAddress",
                        "type": "address"
                    }
                ],
                "internalType": "struct Token1155.Params",
                "name": "_params",
                "type": "tuple"
            }
        ],
        "name": "createCollection1155",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "_name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "_symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "_contractUri",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_rolesContract",
                        "type": "address"
                    },
                    {
                        "internalType": "uint96",
                        "name": "_royalty",
                        "type": "uint96"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_cost",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "_perceptionCollection",
                        "type": "bool"
                    },
                    {
                        "internalType": "uint96",
                        "name": "_maxNftsPerWallet",
                        "type": "uint96"
                    },
                    {
                        "internalType": "bool",
                        "name": "_revealed",
                        "type": "bool"
                    },
                    {
                        "internalType": "string",
                        "name": "_revealUrl",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "_ngoAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_feeNgoPercent",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "_royaltyReceiver",
                        "type": "address"
                    }
                ],
                "internalType": "struct ArtNft.Params",
                "name": "_params",
                "type": "tuple"
            }
        ],
        "name": "createCollection721",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_payees",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_shares",
                "type": "uint256[]"
            },
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            }
        ],
        "name": "createPaymentSplitter",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getCollection1155",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getCollection721",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getPaymentsImplementation",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "roles",
        "outputs": [
            {
                "internalType": "contract IRoles",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_collectionAddress1155",
                "type": "address"
            }
        ],
        "name": "setCollection1155",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_collectionAddress721",
                "type": "address"
            }
        ],
        "name": "setCollection721",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_payments",
                "type": "address"
            }
        ],
        "name": "setPaymentsImplementation",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];