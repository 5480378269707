import * as React from 'react';
import { useQuery } from "graphql-hooks";
import { useParams } from 'react-router-dom';
import { useCollections } from '../../../context/CollectionsContext';
// layout
import { Col, Container, Row } from "react-bootstrap";
import { Typography, Tooltip, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// componentes
import GoBack from "../../../components/GoBack/GoBack";
import Loading from "../../../components/Loading/Loading";
// tabs
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DisplayInfo from '../../../models/EditCollection/DisplayInfo';
import ContractLevelInfo from '../../../models/EditCollection/ContractLevelInfo';

const CollectionConfig = () => {
  const { address } = useParams();
  // query a datos CMS
  const thisCollection = useQuery(`query MyQuery {
    collection(filter: {contractAddress: {eq: "${address}"}}) {
      bannerImage {
        url
      }
      category {
        name
        slug
      }
      coverImage {
        url
      }
      id
      description
      name
    }
  }
  `);

  // query a moralis
  const { get_collection } = useCollections();
  const [collection, setCollection] = React.useState({})
  React.useEffect(() => {
    get_collection(address)
      .then((_collection) => setCollection(_collection))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const [value, setValue] = React.useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (thisCollection.loading || !collection.attributes) return <Loading />

  
  return (
    <Container>
      <GoBack />
      <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1 mb-3">Edit {thisCollection?.data.collection.name} collection</Typography>
      {/* Subtitulo */}
      <Row>
        <Col md={4}>
          <Typography variant="button" color='primary'>Contract Address:</Typography>
          <Typography>{address.slice(0, 5)}...{address.slice(37)} <Tooltip title="Copy address" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(address)}><ContentCopyIcon /></IconButton></Tooltip></Typography>
        </Col>
        <Col md={4}>
          <Typography variant="button" color='primary'>CMS Record Id:</Typography>
          <Typography>{thisCollection?.data.collection.id} <Tooltip title="Copy CMS ID" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(thisCollection?.data.collection.id)}><ContentCopyIcon /></IconButton></Tooltip></Typography>
        </Col>
        <Col md={4}>
          <Typography variant="button" color='primary'>Moralis Id: </Typography>
          <Typography>{(collection.id).toLowerCase().slice(0, 5)}...{(collection.id).toLowerCase().slice(-5)} <Tooltip title="Copy Moralis ID" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(collection.id)}><ContentCopyIcon /></IconButton></Tooltip></Typography>
        </Col>
      </Row>
      {/* Tabs */}
      <Row>
        <TabContext value={value}>
          {/* Header */}
          <TabList onChange={handleChange} aria-label="properties tab" className="my-4">
            <Tab label={"Display Info"} value={"0"} className="px-4" />
            <Tab label={"Contract Level Info"} value={"1"} className="px-4" />
          </TabList>
          <TabPanel className="d-flex flex-column align-items-center p-0" value={"0"}>
            <DisplayInfo _collection={thisCollection?.data.collection} />
          </TabPanel>
          <TabPanel className="d-flex flex-column align-items-center p-0" value={"1"}>
            <ContractLevelInfo _collection={collection.attributes} />
          </TabPanel>
        </TabContext>
      </Row>
    </Container>
  )
}

export default CollectionConfig