import * as React from 'react';
import { rolesAddress, rolesAbi } from '../../../utils/rolesContract';
import { Container, Row, Col } from "react-bootstrap";
import { Typography, Tab } from '@mui/material';
// componentes
import GoBack from "../../../components/GoBack/GoBack";
import ContractAddress from '../../../components/ContractAddress/ContractAddress';
import PauseContract from '../../../components/PauseContract/PauseContract';
// tabs
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GrantRole from '../../../models/Roles/GrantRole';
import RevokeRole from '../../../models/Roles/RevokeRole';
import CreateNewRole from '../../../models/Roles/CreateNewRole';

const RolesAdmin = () => {
    // Variables para el tab de atributos y propiedades
    const [tabValue, setValue] = React.useState("2");
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <GoBack />
            <Row className="d-flex align-items-center">
                <Col>
                    <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1">
                        Roles contract administrator
                    </Typography>
                </Col>
                {/* pausado */}
                <PauseContract _address={rolesAddress} _abi={rolesAbi} />
            </Row>
            <Row className="mb-4">
                <ContractAddress _addr={rolesAddress} _name={'Roles'} />
            </Row>
            
            {/* Tabs de atributos */}
            <TabContext value={tabValue}>
                {/* Header */}
                <TabList onChange={handleTabChange} aria-label="properties tab" >
                    {/* <Tab label="Setters" value="1" /> */}
                    <Tab label="Grant role" value="2" />
                    <Tab label="Revoke roles" value="3" />
                    <Tab label="Create new role" value="4" />
                </TabList>

                {/* <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="1">
                    Setters
                </TabPanel> */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="2">
                    <GrantRole />
                </TabPanel>
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="3">
                    <RevokeRole />
                </TabPanel>
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="4">
                    <CreateNewRole />
                </TabPanel>
            </TabContext>

        </Container>
    )
}

export default RolesAdmin