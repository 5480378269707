import * as React from 'react';
import { Container } from 'react-bootstrap';
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
// Tabs Properties
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DTCsGallery from '../../../models/CardsDisplay/DTCsGallery';
import { useMoralis } from "react-moralis";

const DTCsGalleryArtist = () => {
    const [value, setValue] = React.useState("0");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // moralis
    const { user } = useMoralis();

    if (!user) return

    return (
        <Container>
            <GoBack />
            <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1 mx-2 mb-2">DTCs created by: {user?.attributes.ethAddress.slice(0, 5)}...{user?.attributes.ethAddress.slice(37)}</Typography>
            <TabContext value={value}>
                {/* Header */}
                <TabList onChange={handleChange} aria-label="properties tab" className="my-4">
                    <Tab label={"All the DTCs"} value={"0"} className="px-4" />
                    <Tab label={"Draft"} value={"1"} className="px-4" />
                    <Tab label={"On Sale"} value={"2"} className="px-4" />
                    <Tab label={"Minted"} value={"3"} className="px-4" />
                </TabList>

                {/* Contenido */}
                {/* Todos los NFTs */}
                <TabPanel className="d-flex flex-column align-items-center p-0" value={"0"}>
                    <DTCsGallery _states={[1, 2, 3, 4]} _collection={undefined} _creator={(user?.attributes.ethAddress).toLowerCase()} />
                </TabPanel>
                {/* Borrador */}
                <TabPanel className="d-flex flex-column align-items-center p-0" value={"1"}>
                    <DTCsGallery _states={1} _collection={undefined} _creator={(user?.attributes.ethAddress).toLowerCase()} />
                </TabPanel>
                {/* En venta */}
                <TabPanel className="d-flex flex-column align-items-center p-0" value={"2"}>
                    <DTCsGallery _states={2} _collection={undefined} _creator={(user?.attributes.ethAddress).toLowerCase()} />
                </TabPanel>
                {/* Vendidos */}
                <TabPanel className="d-flex flex-column align-items-center p-0" value={"3"}>
                    <DTCsGallery _states={3} _collection={undefined} _creator={(user?.attributes.ethAddress).toLowerCase()} />
                </TabPanel>
            </TabContext>
        </Container>
    )
}

export default DTCsGalleryArtist