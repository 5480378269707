import * as React from "react";
import ModalCard from "../ModalCard";
import metamask from "../../../assets/metamask-not-installed.png";
import { Typography, Button, Divider } from "@mui/material";

const MetamaskNotInstalledModal = ({ open, handleClose }) => {
    return (
        <ModalCard className="metamask-modal-properties modal-properties" open={open} handleClose={handleClose}>
            <Typography type="blockquoteRegular">{"Install Metamask to continue"}</Typography>
            <Divider className={"divider mt-3"} />
            <div className={"modal-container"}>
                <img src={metamask} width="70" alt="Metamask Logo" />
                <Typography className={"modal-text"}>
                    {"We detected that you do not have Metamask installed on your computer, to continue to the site you need to install it."}
                </Typography>
                <Typography className={"modal-text"}>
                    {"Go to"}
                    &nbsp;
                    <a
                        href="https://metamask.io/download/"
                        target="_blank"
                        rel="noreferrer"
                        className={"link"}>
                        <Typography sx={{ color: 'white' }}>
                            Metamask
                        </Typography>
                    </a>
                </Typography>
            </div>

            <Divider className={"divider"} />

            <div className={"button-section mt-2"}>
                <Button variant="contained" color="info" onClick={handleClose}>
                    <Typography>{"Cancel"}</Typography>
                </Button>
            </div>
        </ModalCard>
    );
};

export default MetamaskNotInstalledModal;
