const useUploading = () => {

    const INITIAL_STATE = {
        loading: false,
        success: false,
        error: false,
    };

    const ACTION_TYPE = {
        FETCH_START: "FETCH_START",
        FETCH_SUCCESS: "FETCH_SUCCESS",
        FETCH_ERROR: "FETCH_ERROR",
    };

    const uploadingReducer = (state, action) => {
        switch (action.type) {
            case ACTION_TYPE.FETCH_START:
                return {
                    ...state,
                    loading: true,
                }
            case ACTION_TYPE.FETCH_SUCCESS:
                return {
                    loading: false,
                    error: false,
                    success: true,
                }
            case ACTION_TYPE.FETCH_ERROR:
                return {
                    error: true,
                    loading: false,
                    post: false,
                }
            default:
                break;
        }
    };

    return {
        INITIAL_STATE,
        ACTION_TYPE,
        uploadingReducer
    }
}

export default useUploading