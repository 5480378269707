import * as React from 'react';
import { ethers } from 'ethers';
import { Row, Col } from 'react-bootstrap';
import { TextField, Button, Typography, Tooltip, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelperIcon from "../../components/HelperIcon/HelperIcon"

const ContractSetter = ({ _key, _value, _onClick, _type, _helper = '' }) => {
    // nuevo value
    const [newValue, setNewValue] = React.useState()
    // chequeo disabled
    const [disabled, setDisabled] = React.useState(true);
    const check_disabled = () => {
        let bool = true;
        switch (_type) {
            case 'number':
                bool = (Number(newValue) === 0 || Number(newValue) === Number(_value)) ? true : false
                break;
            case 'text':
                bool = (!(ethers.utils.isAddress(newValue)) || (newValue) === (_value)) ? true : false
                break;
            default: bool = true;
                break;
        };
        setDisabled(bool)
    };
    // cada vez que cambia newValue se fija que sea un valor valido
    React.useEffect(() => {
        check_disabled();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValue])

    // funcion que cambia
    const handleSubmit = async (_input) => await _onClick(_input)

    return (
        <>
            <Row className="text-left d-flex align-items-start">
                {_helper !== '' &&
                    <Col md={1}>
                        <HelperIcon _help={_helper} />
                    </Col>
                }
                <Col>
                    <Typography variant="h6" component="h5" sx={{ wordBreak: 'break-all', marginBottom: '.5rem', fontWeight: '600' }} className="setters" color='primary'>
                        {_key}: &nbsp; {_type !== 'text' ? _value : <><span style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: '500' }}>{_value.slice(0, 5)}...{_value.slice(37)}</span> <Tooltip title="Copy address" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(_value)}><ContentCopyIcon /></IconButton></Tooltip></>}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <div style={{ marginBottom: '1rem', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingRight: '2rem' }}>
                    <TextField className="selector" type={_type} id="selector" label={`New ${_key?.toLowerCase()}`} name="selector" variant="outlined"
                        sx={{ margin: '0px', marginRight: '1rem', width: '50ch' }} onChange={(e) => setNewValue(e.target.value)}
                    />
                    <Button variant="contained" color='secondary'
                        disabled={disabled}
                        sx={{ padding: '1rem 1.5rem' }} onClick={() => handleSubmit(newValue)}>
                        Accept
                    </Button>
                </div>
            </Row>
        </>
    )
}

export default ContractSetter