export const DTC_INITIAL_STATE = {
    name: "",
    description: "",
    collection: "",
    collectionAddress: "",
    category: "",
    categoryId: "",
    image: undefined,
    properties: [],
    stats: [],
    rankings: [],
    attributes: [],
    totalSupply: undefined,
};

export const ACTION_TYPE = {
    CHANGE_INPUT: "CHANGE_INPUT",
    SET_IMAGE: "SET_IMAGE",
    SET_ATTRIBUTES: "SET_ATTRIBUTES",
    ADD_ATTRIBUTE: "ADD_ATTRIBUTE",
    CHANGE_ATTRIBUTE: "CHANGE_ATTRIBUTE",
    REMOVE_ATTRIBUTE: "REMOVE_ATTRIBUTE",
};

export const dtcReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE.CHANGE_INPUT:
            let newCollectionAddress = state.collectionAddress;
            if (action.payload.address) newCollectionAddress = action.payload.address;
            let newCategoryId = state.categoryId;
            if (action.payload.cmsId) newCategoryId = action.payload.cmsId;
            
            return {
                ...state,
                [action.payload.name]: action.payload.value,
                collectionAddress: newCollectionAddress,
                categoryId: newCategoryId,
            }
        case ACTION_TYPE.SET_IMAGE:
            return {
                ...state,
                [action.payload.name]: { source: action.payload.value, isVideo: action.payload.isVideo },
            }
        case ACTION_TYPE.SET_ATTRIBUTES:
            return {
                ...state,
                attributes: [...state.properties, ...state.rankings, ...state.stats],
            }
        case ACTION_TYPE.ADD_ATTRIBUTE:
            const oldValue = state[action.payload.name]
            return {
                ...state,
                [action.payload.name]: [...oldValue, action.payload.value],
            }
        case ACTION_TYPE.CHANGE_ATTRIBUTE:
            let newValue = state[action.payload.type];
            if (action.payload.name === "trait_type") newValue[action.payload.index]["trait_type"] = action.payload.value;
            if (action.payload.name === "value") newValue[action.payload.index]["value"] = action.payload.value;
            if (action.payload.type === "stats"){
                 newValue[action.payload.index]["display_type"] = "number";
                 if (action.payload.name === "value") newValue[action.payload.index]["value"] = Number(action.payload.value);
            }
            return {
                ...state,
                [action.payload.type]: newValue,
            }
        case ACTION_TYPE.REMOVE_ATTRIBUTE:
            let newValues = state[action.payload.name]
            const indexToRemove = newValues.map(values => {
                return values.id;
            }).indexOf(action.payload.value);
            newValues.splice(indexToRemove, 1);
            return {
                ...state,
                [action.payload.name]: newValues,
            }
        default:
            return {
                ...state
            }
    }
}