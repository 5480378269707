// cosas de react
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// moralis
import { MoralisProvider } from "react-moralis";
// contexts
import { UserProvider } from './context/UserContext';
// react-router-dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// material-ui / tema del panel
import { ThemeProvider } from "@mui/material";
import customTheme from "./Theme/Theme"
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { CategoriesProvider } from './context/CategoriesContext';
import { IPFSProvider } from './context/IPFSContext'
import { CollectionsProvider } from './context/CollectionsContext';
import { NetworkProvider } from './context/NetworkContext';
import { DTCsProvider } from './context/DTCsContext';
import { CMSProvider } from './context/CMSContext';
import { CampaignProvider } from './context/CampaignContext';
import { GraphQLClient, ClientContext } from 'graphql-hooks';

const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    "Authorization": `Bearer ${process.env.REACT_APP_CMS_READ_API_KEY}`,
    "Content-Type": 'application/json',
    "Accept": 'application/json',
    "X-Environment": process.env.REACT_APP_CMS_ENVIRONMENT,
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <ClientContext.Provider value={client}>
        <MoralisProvider appId={process.env.REACT_APP_MORALIS_API} serverUrl={process.env.REACT_APP_MORALIS_SERVER}>
          <Router>
            <CMSProvider>
              <IPFSProvider>
                <UserProvider>
                  <NetworkProvider>
                    <CategoriesProvider>
                      <CollectionsProvider>
                        <DTCsProvider>
                          <CampaignProvider>
                            <Routes>
                              <Route path="/*" element={<App />} />
                            </Routes>
                          </CampaignProvider>
                        </DTCsProvider>
                      </CollectionsProvider>
                    </CategoriesProvider>
                  </NetworkProvider>
                </UserProvider>
              </IPFSProvider>
            </CMSProvider>
          </Router>
        </MoralisProvider>
      </ClientContext.Provider>
    </ThemeProvider>
  // </React.StrictMode>
);

