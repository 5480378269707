import React from "react";
import { ethers } from "ethers";
import { useMoralisWeb3Api } from "react-moralis";
import Moralis from "moralis-v1";
import { useCollections } from "../../../context/CollectionsContext";

//Contracts
import { lazyMintAddress, lazyMintAbi } from "../../../utils/lazyMintContract";
//MUI
import { Container, Col, Row } from "react-bootstrap";
import {  Typography,  Box,  InputLabel,  MenuItem,  FormControl,  Select } from "@mui/material";
//Components
import GoBack from "../../../components/GoBack/GoBack";
import BasicTable from "../../../components/BasicTable/BasicTable";

const Dashboard = () => {
  const Web3Api = useMoralisWeb3Api();
  const [totalSupply, setTotalSupply] = React.useState(0);
  const [selectedContract, setSelectedContract] = React.useState("");
  const [priceSelectedContract, setPriceSelectedContract] = React.useState(0)
  // Loading que se mantiene hasta que se realizan las operaciones de lectura directo del contrato
  //const [initialLoading, setInitialLoading] = React.useState(true);
  const { get_all_collections, allCollections, get_collection } = useCollections();

  const handleChange = async (event) => {
    setSelectedContract(event.target.value);
    get_all_tokens(event.target.value)
    
  };

  const buttonStyle = {
    color: "white",
    backgroundColor: "#2A407E",
    width: "230px",
    height: "70px",
    display: "flex",
    borderRadius: "10px",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
  };

  /* Cantidad de NFTs vendidos */
  const [nftsSold, setNftsSold] = React.useState(0);
  const get_nfts_solds = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        let contract = new ethers.Contract(
          lazyMintAddress,
          lazyMintAbi,
          provider
        );
        await contract.tokensSold().then((response) => {
          setNftsSold(response.toNumber());
        });
      } else {
        console.error("No hay conexion a Metamask");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const get_total_dtcs = async () => {
    try {
      const response = await Moralis.Cloud.run("CountDtcs");
      let aux = 0;
      response.map((response, index) => {
        return  aux = aux + response.attributes.totalSupply;
      });
      setTotalSupply(aux);
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(`Error`);
    }
  };

  /* Lista de compradores */
  const [buyers, setBuyers] = React.useState([]);
  const get_all_tokens = async (_contractAddress) => {
    const options = {
      address: _contractAddress, // Address de la colección
      chain: "matic",
    };
    await Web3Api.token.getNFTOwners(options).then((r) => setBuyers(r.result));
    const collection = await get_collection(_contractAddress)
    setPriceSelectedContract(collection.attributes.price)
  };

/*   const get_buyers = async (_tokens) => {
    for (let i = 0; i < _tokens.length; i++) {
      const element = _tokens[i];
      let alreadyIn = buyers.find(
        (buyer) => buyer.account === element.owner_of
      );
      if (alreadyIn) {
        let index = buyers.findIndex(
          (buyer) => buyer.account === element.owner_of
        );
        let oldArray = buyers;
        oldArray[index].amount += 1;
        setBuyers(oldArray);
      } else {
        setBuyers((prevState) => [
          ...prevState,
          { account: element.owner_of, amount: 1 },
        ]);
      }
    }
  }; */

  React.useEffect(() => {
    get_nfts_solds();
    get_total_dtcs();
    get_all_tokens();
    get_all_collections({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Container>
        <GoBack />
        <Row className="d-flex align-items-center justify-content-start">
          <Typography
            variant="h5"
            component="h2"
            color="secondary"
            sx={{
              marginBottom: "0",
              marginTop: "0.75rem",
              fontWeight: "700",
              fontSize: "32px",
            }}
          >
            DTCs dashboard
          </Typography>
        </Row>
        <Row className="mt-4">
          <Col className='d-flex justify-content-center'>
            <Box sx={buttonStyle}>
              <label style={{ fontSize: "16px", fontWeight: "400" }}>
                DTCs to sell:
              </label>
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                {totalSupply}
              </label>
            </Box>
          </Col>
          <Col className='d-flex justify-content-center'>
            <Box sx={buttonStyle}>
              <label style={{ fontSize: "16px", fontWeight: "400" }}>
                DTCs Sold:
              </label>
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                {nftsSold}
              </label>
            </Box>
          </Col>
          {/* <Col>
          <Box sx={buttonStyle}>
              <label style={{fontSize: "16px", fontWeight: "400"}}>DTC price in USD:</label>
              <label style={{fontSize: '18px', fontWeight: 'bold'}}>{_value}</label>
            </Box>
          </Col>
          <Col>
          <Box sx={buttonStyle}>
              <label style={{fontSize: "16px", fontWeight: "400"}}>DTC price in MATIC:</label>
              <label style={{fontSize: '18px', fontWeight: 'bold'}}>{_value}</label>
            </Box>
          </Col> */}
        </Row>
        <Row className="mt-5">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Collections</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedContract}
                label="Contract"
                onChange={handleChange}
              >
                {allCollections?.map((collection, index) => (
                        
                         <MenuItem key={index} value={collection.attributes.contractAddress}>{collection.attributes.displayName}</MenuItem>
                    ))
                }

              </Select>
            </FormControl>
          </Box>
        </Row>
        <Row className="mt-5">
         {buyers.length > 0 
         ? 
         <BasicTable _data={buyers} _price={priceSelectedContract}/>
         : 
         <h4>No info to show</h4>}
            
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
