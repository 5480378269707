/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import logo from '../../assets/DTC_title.svg'
// Componentes para el menu lateral
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';
import { Tooltip, Button, IconButton, Divider, List, ListItem, ListItemText, Box, ListItemIcon, ListItemButton, Toolbar, Collapse, Menu, MenuItem, Typography } from '@mui/material';
// Segun los roles se renderizan distintas opciones en el menu
import { useMoralis } from 'react-moralis';
import { useUser } from '../../context/UserContext';
// Mui Icons
import CollectionsIcon from '@mui/icons-material/Collections';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CreateIcon from '@mui/icons-material/Create';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HiveIcon from '@mui/icons-material/Hive';

const drawerWidth = 240;
const transitionDuration = '0.5s'

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: transitionDuration,
        }),
    }),
}));

const CustomLink = ({ to, children }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <Link
            style={{ color: match ? "#AE74FF" : "inherit" }}
            to={to}
        >
            {children}
        </Link>
    );
}

const DrawerLayout = ({ children }) => {

    const { user } = useMoralis();
    const { disconnect_wallet, ROLES_HASH } = useUser();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [nestedOpen, setNestedOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleNestedList = () => {
        setNestedOpen(!nestedOpen);
    };

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            {/* NAVBAR */}
            <AppBar position="fixed" open={open} className="shadow-none" color="info">
                <Toolbar className="d-flex align-items-center justify-content-between" sx={{ minHeight: '7vh !important' }}>
                    <div className="d-flex align-items-center">
                        <Tooltip title="Mostrar menu">
                            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ marginRight: 5, ...(open && { display: 'none' }) }}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CustomLink to="/panel">
                            <img src={logo} alt="logo" />
                        </CustomLink>
                    </div>
                    <div className="d-flex align-items-center" style={{ marginRight: '10rem' }}>
                        <Button color="primary" onClick={handleOpenUserMenu} sx={{ fontSize: '1rem' }}
                            startIcon={<AccountCircleIcon fontSize='inherit' />} endIcon={<KeyboardArrowDownIcon />}>
                            Welcome!
                        </Button>
                        <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            <MenuItem className="d-flex justify-content-between align-items-center">
                                <Typography variant="button" textAlign="start">{user?.attributes.ethAddress.slice(0, 5)}...{user?.attributes.ethAddress.slice(37)}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Tooltip sx={{ padding: '0' }} title="Copy address" arrow placement="right"><IconButton onClick={() => navigator.clipboard.writeText(user?.attributes.ethAddress)}><ContentCopyIcon /></IconButton></Tooltip></Typography>
                            </MenuItem>
                            <MenuItem className="d-flex justify-content-between align-items-center">
                                <Typography variant="button" textAlign="center">Logout</Typography>
                                <IconButton aria-label="logout" className="px-0 mx-0" onClick={disconnect_wallet} color="primary">
                                    <LogoutIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>

                    </div>
                </Toolbar>
            </AppBar >
            {/* DRAWER */}
            <Drawer variant="permanent" open={open} sx={{ [`& .MuiDrawer-paper`]: { backgroundColor: '#2A407E' }, }}>
                {/* Flecha que cierra */}
                <DrawerHeader sx={{ minHeight: '7vh !important' }}>
                    <Tooltip title="Esconder menu">
                        <IconButton onClick={handleDrawerClose} sx={{ color: '#FFFFFF' }}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </Tooltip>
                </DrawerHeader>
                <Divider sx={{ borderColor: '#FFFFFF' }} />
                {/* Primera lista */}
                <List sx={{ color: '#FFFFFF' }}>
                    {/* CREAR DTC */}
                    <CustomLink to="panel/create-dtc">
                        <ListItem button>
                            <ListItemIcon sx={{ color: 'inherit' }}>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Create DTC"} />
                        </ListItem>
                    </CustomLink>
                    {
                        (user?.attributes.roleHash === ROLES_HASH.owner_hash || user?.attributes.roleHash === ROLES_HASH.admin_hash) &&
                        <>
                            {/* Categories */}
                            <CustomLink to="panel/categories">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <LibraryBooksIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Categories"} />
                                </ListItem>
                            </CustomLink>
                            {/* DTC gallery */}
                            <CustomLink to="panel/admin/gallery">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <CollectionsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"DTC gallery"} />
                                </ListItem>
                            </CustomLink>
                            {/* Minteo masivo */}
                            {<CustomLink to="panel/admin/create-campaign">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <HiveIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Create Campaign"} />
                                </ListItem>
                            </CustomLink>}
                        </>}

                    {
                        (user?.attributes.roleHash === ROLES_HASH.artist_hash) &&
                        <>
                            <CustomLink to="panel/artist/dtcs">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <CollectionsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"DTC gallery"} />
                                </ListItem>
                            </CustomLink>
                        </>}

                </List>
                {/* Segunda lista */}
                {/* ADMINISTRACION */}
                {
                    (user?.attributes.roleHash === ROLES_HASH.owner_hash || user?.attributes.roleHash === ROLES_HASH.admin_hash) &&
                    <>
                        <Divider sx={{ borderColor: '#FFFFFF' }} />
                        <List sx={{ color: '#FFFFFF' }}>
                            {/* Administracion */}
                            <ListItemButton onClick={handleNestedList}>
                                <ListItemIcon sx={{ color: 'inherit' }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Administration" />
                                {nestedOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    {/* <CustomLink to="panel">
                                        <ListItem button>
                                            <ListItemIcon sx={{ color: 'inherit', pl: 1.5 }}>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={"Mystery Box"} />
                                        </ListItem>
                                    </CustomLink> */}

                                    <CustomLink to="panel/admin/roles">
                                        <ListItem button>
                                            <ListItemIcon sx={{ color: 'inherit', pl: 1.5 }}>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={"Roles"} />
                                        </ListItem>
                                    </CustomLink>

                                    <CustomLink to="panel/admin/collector">
                                        <ListItem button>
                                            <ListItemIcon sx={{ color: 'inherit', pl: 1.5 }}>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={"Collector"} />
                                        </ListItem>
                                    </CustomLink>

                                </List>
                            </Collapse>

                            {/* Contratos */}
                            <CustomLink to="panel/admin/contracts">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <ArticleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Contracts"} />
                                </ListItem>
                            </CustomLink>

                            {/* Dashboard */}
                            <CustomLink to="panel/admin/dashboard">
                                <ListItem button>
                                    <ListItemIcon sx={{ color: 'inherit' }}>
                                        <BarChartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Dashboard"} />
                                </ListItem>
                            </CustomLink>
                        </List>
                    </>
                }
            </Drawer >
            {/* CONTENIDO */}
            <Box component="main" sx={{ flexGrow: 1, p: 3, minHeight: '100vh', paddingBottom: '0px' }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box >
    )
}

export default DrawerLayout