import * as React from 'react'
import { ethers } from 'ethers';
import { Container, Row } from 'react-bootstrap';
import { rolesAddress, rolesAbi } from '../../utils/rolesContract'
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import BootstrapInput from '../../components/Inputs/BootstrapInput';
// modales
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import useUploading from "../../hooks/useUploading";
import { useNetwork } from '../../context/NetworkContext';

const GrantRole = () => {
    // Variables
    const [role, setRole] = React.useState("");
    const [roleDisplay, setRoleDisplay] = React.useState("");
    const [walletAddress, setWalletAddress] = React.useState("");
    
    // const [name, setName] = React.useState("");
    // const [disableBtn, setDisableBtn] = React.useState(false);

    // custom hooks y reducers juntos
    const wait = useUploading();
    const [waitState, waitDispatch] = React.useReducer(wait.uploadingReducer, wait.INITIAL_STATE);
    
    // Máximo gas
    const { getMaxGas } = useNetwork();
    // Funciones
    const handleChange = (event) => {
        setRoleDisplay(event.target.value)
        setRole(ethers.utils.keccak256(ethers.utils.toUtf8Bytes(event.target.value)));
    };
    const grant_role = async () => {
        waitDispatch({ type: wait.ACTION_TYPE.FETCH_START });
        try {
            const { ethereum } = window;
            if (ethereum) {
                // Conecto la wallet
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                let contract = new ethers.Contract(rolesAddress, rolesAbi, signer);
                // Obtenemos el maximo gas del momento
                let fastestGas = await getMaxGas();
                const tx = await contract.grantRole(role, walletAddress, { gasPrice: fastestGas });
                await tx.wait()
                waitDispatch({ type: wait.ACTION_TYPE.FETCH_SUCCESS });
            } else {
                console.error("No hay conexion a Metamask")
            }
        } catch (error) {
            console.log(error);
            waitDispatch({ type: wait.ACTION_TYPE.FETCH_ERROR });
        }
    };
    return (
        <>
            <WaitingModal _open={waitState.loading} _handleClose={() => null} _title={"Granting role"} _subtitle={`A new role is being granted. Please wait.`} />
            <ConfirmationModal _open={waitState.success} _handleClose={() => null} _title={"Role granted"} _subtitle={`A new role has been granted and successfully uploaded in the db!`} _redirect={`/panel/admin/roles`} />
            <Container fluid>
                {/* <Row className="my-4 w-75"><BootstrapInput className="w-100 px-0" type="text" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} /></Row> */}
                <Row className="my-4 w-75"><BootstrapInput className="w-100 px-0" type="text" value={walletAddress} placeholder="Address" onChange={(e) => setWalletAddress(e.target.value)} /></Row>
                {/* SELECT ROLES */}
                <Row className="my-4 w-75">
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Role</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={roleDisplay}
                            label="Role"
                            onChange={handleChange}
                        >
                            <MenuItem value="NFT_ADMIN_ROLE">Admin</MenuItem>
                            <MenuItem value="ARTIST_ROLE">Artist</MenuItem>
                            <MenuItem value="LAZY_MINT_CONTRACT">Lazy Mint</MenuItem>
                            <MenuItem value="RELAYER_ROLE">Relayer</MenuItem>
                        </Select>
                    </FormControl>
                </Row>
                {/* button */}
                <Row className="my-4 w-25">
                    <Button onClick={() => grant_role()} variant="contained" disabled={!ethers.utils.isAddress(walletAddress)}>
                        Confirm
                    </Button>
                </Row>
            </Container>
        </>
    )
}

export default GrantRole