/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery } from "graphql-hooks";
import { useParams } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import CardsDisplay from '../../../models/CardsDisplay/CardsDisplay';
import Loading from "../../../components/Loading/Loading";

const Category = () => {
  let { category } = useParams();

  // paginacion
  const [page, setPage] = React.useState(1);
  const handlePaginationChange = (e, value) => {
    setPage(value)
  };
  const limit = 8;

  const thisCategory = useQuery(`query MyQuery {
    category(filter: {slug: {eq: ${category}}}) {
      name
      bannerImage {
        url
      }
      coverImage {
        url
      }
      id
    }
  }`);

  

  const collectionsIn = useQuery(`query MyQuery {
    allCollections(filter: {category: {eq: ${thisCategory.data?.category.id}}}, skip: ${(Number(page - 1) * limit)}, first: ${limit}) {
      id
      name
      slug
      description
      coverImage {
        url
      }
      contractAddress
      bannerImage {
        url
      }
      destination
    }
  }`);

  const allCollectionsIn = useQuery(`query MyQuery {
    allCollections (filter: {category: {eq: ${thisCategory.data?.category.id}}}, first: 100, skip: 0) {
      slug
    }
  }`);

  if (thisCategory.loading || allCollectionsIn.loading || collectionsIn.loading) return <Loading />
  
  return (
    <Container>
      <GoBack />
      <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-1 mx-2 mb-5">{thisCategory?.data.category.name}</Typography>
      <CardsDisplay _cards={collectionsIn?.data?.allCollections} _length={allCollectionsIn?.data?.allCollections.length} _type={'collections'}
        _page={page} _limit={limit} _handlePaginationChange={handlePaginationChange} />
    </Container>
  )
}

export default Category