import * as React from "react";
import axios from "axios";

const IPFSContext = React.createContext([]);

IPFSContext.displayName = "IPFSContext";

export const IPFSProvider = ({ children }) => {

    // funcion que sube una imagen y devuelve el hash
    const upload_image = async (_file) => {
        const data = new FormData();
        data.append("file", _file);
        try {
            const response = await axios.post(`https://api.pinata.cloud/pinning/pinFileToIPFS`, data, {
                maxBodyLength: "Infinity",
                headers: {
                    pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                    pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
                },
            });
            return response.data.IpfsHash;
        } catch (error) {
            console.log(error);
            throw new Error('Error in the context while uploading the image to IPFS');
        }
    };

    // funcion que sube un JSON y devuelve el hash
    const upload_json = async (_json) => {
        try {
            const response = await axios.post(`https://api.pinata.cloud/pinning/pinJSONToIPFS`,
                {
                    pinataMetadata: {
                        name: _json.name ? _json.name : 'Undefined name',
                    },
                    pinataContent: _json,
                },
                {
                    headers: {
                        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
                    },
                }
            );

            return response.data.IpfsHash
        } catch (error) {
            console.error(error)
            throw new Error("Error in the context while uploading the JSON to IPFS");
        }
    };

    return (
        <IPFSContext.Provider value={{
            upload_image,
            upload_json,
        }}>
            {children}
        </IPFSContext.Provider>
    );
};

export const useIPFS = () => {
    const context = React.useContext(IPFSContext);
    return context;
};