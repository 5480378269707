import * as React from 'react';
import { Container } from "react-bootstrap";
import { Typography } from '@mui/material';
import GoBack from "../../../components/GoBack/GoBack";
import CreateCategory from '../../../models/CreateCategory/CreateCategory';

const CreateCategories = () => {
    return (
        <>
            <GoBack />
            <Container>
                <Typography variant="h4" component="h2" sx={{ fontWeight: '700' }} color="primary" className="mt-4">Create Category</Typography>
                <CreateCategory />
            </Container>
        </>
    )
}

export default CreateCategories