import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ethers } from 'ethers';
// mis componentes
import BootstrapInput from '../../components/Inputs/BootstrapInput';
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
//import CheckModal from '../../components/Modals/CheckModal/CheckModal';
import { Typography, Button } from '@mui/material';
import { erc1155Abi } from '../../utils/erc1155Contract';
import { useNetwork } from '../../context/NetworkContext'


const ContractLevelInfo = ({ _collection }) => {
  const [newFee, setNewFee] = React.useState(false)
  const [openFee, setOpenFee] = React.useState(false);
  const [settingFee, setSettingFee] = React.useState(false)
  const [error, setError] = React.useState(false)
  const { getMaxGas } = useNetwork();

  const changeFee = async (_newFee) => {
    setOpenFee(false);
    setSettingFee(true)
    try {
      const { ethereum } = window;
      if (ethereum) {
        // Conecto al contrato
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const fastestGas = await getMaxGas();
        const signer = provider.getSigner();
        let contract = new ethers.Contract(_collection.contractAddress, erc1155Abi, signer);
        // Llamo a la funcion
        const tx = await contract.setLazyMintingPrice(ethers.utils.parseEther(_newFee), { gasPrice: fastestGas, gasLimit: 100000 });
        await tx.wait()
          .then(() => setSettingFee(false))
          .then(() => setOpenFee(true))
          .then(() => window.location.reload())
          .catch((e) => console.log(e))
      } else {
        console.log("No hay conexion a Metamask");
      }
    } catch (error) {

      setError(true)
      setSettingFee(false)
    }
  };

  return (
    <>
      <WaitingModal _open={settingFee} _handleClose={() => null} _title={"Updating Price"} _subtitle={`The price is being updated. Please wait.`} />
      <ConfirmationModal _open={openFee} _handleClose={() => null} _title={"Price updated"} _subtitle={`The price was updated`} _redirect={window.location.pathname} />
      <ConfirmationModal _open={error} _handleClose={() => setError(false)} _title={"There was an error updating the price"} _subtitle={`Please check the console for more information`} _redirect={window.location.pathname} />
      <Container>
        <Row>
          <Typography variant="h6" component="h3" color="primary" className="my-2">Current price: {ethers.utils.formatEther(_collection.price)} USDT</Typography>
          <Col md={8} className='d-flex'>
            <BootstrapInput name="name" className="p-0 w-75" placeholder="New Price" type="text" onChange={(e) => setNewFee(e.target.value)} />
            <Button type="submit" color="secondary" variant="contained" onClick={() => changeFee(newFee)} sx={{ fontSize: '0.9rem', marginLeft: '1rem' }}
              disabled={(Number(newFee) === 0) ? true : false} >
              Update Price
            </Button>
          </Col>
        </Row>
      </Container>
    </>


  )
}

export default ContractLevelInfo